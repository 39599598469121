import { useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { MoreHorizontal } from "lucide-react";

// Components
import { notification } from "../../../utils/notification";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import CrudModal from "../../../components/CrudModal/CrudModal";

// API
import { IScheme, useGetSchemesQuery, useReadSchemeQuery, useRemoveSchemeMutation } from "../../../api/scheme";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";
import SchemeListModalInfo from "./crud-modal-parts/SchemeListInfo";
import { useDebouncedState } from "@mantine/hooks";
import SchemeListModalForm from "./crud-modal-parts/SchemeListForm";
import { IHall, useGetHallsQuery } from "../../../api/hall";
import { toNumber } from "lodash";

const initialModalState = {
    opened: false,
    inEdit: false
};
export const [SchemeFormProvider, useSchemeFormContext, useSchemeForm] = createFormContext<IScheme>();

const SchemeList = () => {
    const roles = useAppSelector(state => state!.auth.user.role);
    const canEditScheme = roles?.some(role => ["administrators"].includes(role));

    const { t } = useTranslation();
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "" | null, inEdit: boolean }>(initialModalState);
    const [page, setPage] = useState<number>(1);
    const [removeScheme] = useRemoveSchemeMutation();
    const selectedEntity = useAppSelector((state) => state.auth.selectedEntity);
    const page_size = 10;
    const [search, setSearch] = useDebouncedState("", 200);

    const { data = { results: [], count: 0 }, isFetching } = useGetSchemesQuery({
        page,
        page_size: 10,
        entity: selectedEntity,
        name: search
    });

    const { data: schemeRecord, isFetching: isFetchingRecord } = useReadSchemeQuery(
        {
            id: Number(modalState.record)
        },
        { skip: !modalState.record }
    );

    const { data: halls } = useGetHallsQuery({
        page,
        page_size,
        entity: selectedEntity
    });

    const form = useSchemeForm({
        initialValues: {
            name: ""
        }
    });

    async function onDelete() {
        try {
            modalState.record && (await removeScheme({ id: modalState.record }).unwrap());
            notification({ type: "success", title: t("scheme.notifications.deleted") });
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    function displayHall(hallId: number, displayName: boolean): string | IHall | null | undefined {
        const hallList = halls?.results ?? [];
        const hall = hallList.find((h: IHall) => h.id === hallId) || null;

        return displayName ? hall?.name || "-" : hall;
    }

    return (
        <PageContainer>
            <Text mt={0} mb={30} size={"xl"}>
                {t("scheme.scheme")}
            </Text>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size),
                }}
                columns={[
                    {
                        label: t("buildings.building"), name: "building", render: (value) => {
                            return value?.name || "-";
                        }
                    },
                    {
                        label: t("halls.hall"), name: "hall", render: (value) => {
                            return displayHall(value, true) || "-";
                        }
                    },
                    { label: t("scheme.form.name"), name: "name" },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                        width: "50px",
                        render: (_, record) => {
                            return (
                                <ActionIcon
                                    variant={"transparent"}
                                    color={"dark.1"}
                                    onClick={() =>
                                        setModalState({
                                            opened: true,
                                            record: record.id,
                                            inEdit: false,
                                        })
                                    }
                                >
                                    <MoreHorizontal />
                                </ActionIcon>
                            );
                        },
                    },
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable={canEditScheme ? true : false}
                onSearch={(e) => {
                    setSearch(e);
                }}
                enableSearch
                onClickCreate={
                    () => setModalState(
                        {
                            opened: true,
                            inEdit: true
                        }
                    )
                }
                searchPlaceholder={t("scheme.search")}
                createTitle={t("scheme.create")}
            />

            <CrudModal
                type={"drawer"}
                formId={"SchemeForm"}
                title={modalState.record ? schemeRecord?.name : t("scheme.create")}
                opened={modalState.opened}
                isUpdate={Boolean(modalState.record) && canEditScheme}
                onClose={
                    () => {
                        setModalState(initialModalState);
                        form.reset();
                    }
                }
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={
                    (isEdit) => {
                        setModalState(
                            (prevState) => (
                                {
                                    ...prevState,
                                    inEdit: isEdit,
                                }
                            )
                        );
                    }
                }
            >
                <SchemeFormProvider form={form}>
                    {modalState.inEdit ? (
                        <SchemeListModalForm
                            canEditScheme={canEditScheme!}
                            onClose={
                                () => {
                                    setModalState(
                                        (prevState) => (
                                            {
                                                ...prevState,
                                                inEdit: false,
                                                opened: Boolean(prevState.record)
                                            }
                                        )
                                    );
                                }
                            }
                            recordId={toNumber(modalState.record)}
                            halls={halls?.results || []}
                        />
                    ) : (
                        <SchemeListModalInfo record={schemeRecord} isFetching={isFetching} />
                    )}
                </SchemeFormProvider>
            </CrudModal>
        </PageContainer>
    );
};

export default SchemeList;
