import { notification } from "../../../../../utils/notification";
import { SectorProperties } from "../interfaces/SchemeEdit.interface";

export const setSectorSelected = (
    key: string,
    setSelectedDots: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    data: any,
    setData: React.Dispatch<React.SetStateAction<any>>,
    deSelectAll: () => void
) => {
    const nameParter = key.split(":")[0];
    const dots = Object.keys(data.data).filter(dataKey => dataKey.startsWith(nameParter));

    if (nameParter !== 'null') {
        deSelectAll();
        setSelectedDots(dots);

        setData((prevData: { data: Record<string, any> }) => ({
            ...prevData,
            data: Object.fromEntries(
                Object.entries(prevData.data).map(([key, value]) => {
                    const updatedValue = { ...value };
                    if (dots.includes(key)) {
                        updatedValue.selected = true;
                    }
                    return [key, updatedValue];
                })
            )
        }));
    }
};

export const updateSectorData = (
    addRowsColsToSector: SectorProperties,
    newData: any,
    key: string,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setSelectedKey?: React.Dispatch<React.SetStateAction<string>>,
) => {
    const { nameParter, rows, cols, stepX, stepY, sectorOrientationLeftRight, sectorOrientationUpDown } = addRowsColsToSector;

    if (!nameParter || !rows || !cols || !stepX || !stepY) {
        return;
    }

    const updatedData = { ...newData.data };
    const seatKeyToCheck = `${nameParter}:1:1`;

    // Handle the scenario where the sector is being generated for the first time,  
    // and the sector does not yet exist in data.data.
    if (key.startsWith("null:")) {
        delete updatedData[key];
    }

    // Handle the scenario where a sector with the same nameParter already exists.  
    // Before generating a new sector with the same name, the previous one must be removed.
    if (updatedData[seatKeyToCheck]) {
        Object.keys(updatedData).forEach((key) => {
            if (key.startsWith(nameParter)) {
                delete updatedData[key];
            }
        });
    }

    setSelectedKey!(nameParter + ':1' + ':1');

    if (!newData.data[key]) {
        notification({
            type: "error",
            title: `Failed sector generation - newData.data[${key}] is undefined`
        })
        return;
    }

    const baseX = parseFloat(newData.data[key].rect.x);
    const baseY = parseFloat(newData.data[key].rect.y);

    let rowOrder: number[] = [];
    if (sectorOrientationUpDown === "UpToDown") {
        rowOrder = Array.from({ length: rows! }, (_, index) => index + 1);
    } else if (sectorOrientationUpDown === "DownToUp") {
        rowOrder = Array.from({ length: rows! }, (_, index) => rows! - index);
    }

    for (let rowIndex = 0; rowIndex < rowOrder.length; rowIndex++) {
        const row = rowOrder[rowIndex];

        let columnOrder: number[] = [];
        if (sectorOrientationLeftRight === "LeftToRight") {
            columnOrder = Array.from({ length: cols! }, (_, index) => index + 1);
        } else if (sectorOrientationLeftRight === "RightToLeft") {
            columnOrder = Array.from({ length: cols! }, (_, index) => cols! - index);
        }

        for (let colIndex = 0; colIndex < columnOrder.length; colIndex++) {
            const col = columnOrder[colIndex];
            const seatKey = `${nameParter}:${row}:${col}`;
            updatedData[seatKey] = {
                rect: {
                    x: (baseX + colIndex * stepX!).toFixed(3),
                    y: (baseY + rowIndex * stepY!).toFixed(3),
                },
            };
        }
    }

    const newState = { ...newData, data: updatedData };
    setData(newState);
}
