import { NumberInput, ColorInput, Flex, ActionIcon, Tooltip } from "@mantine/core";
import { CircleStyles } from "../../../interfaces/SchemeEdit.interface";
import { X } from "lucide-react";

interface Props {
    circleStyles: CircleStyles;
    setCircleStyles: React.Dispatch<React.SetStateAction<CircleStyles>>;
    t: (key: string) => string;
}

const CircleStylesPanel: React.FC<Props> = ({ circleStyles, setCircleStyles, t }) => {
    return (
        <>
            <Flex align={"center"} gap={10}>
                <NumberInput
                    label={t('scheme.redactor.panelOptions.setFigure.rect.x')}
                    value={circleStyles.x}
                    onChange={(value) => setCircleStyles((prev) => ({ ...prev, x: value.toString() }))}
                    hideControls
                />
                <NumberInput
                    label={t('scheme.redactor.panelOptions.setFigure.rect.y')}
                    value={circleStyles.y}
                    onChange={(value) => setCircleStyles((prev) => ({ ...prev, y: value.toString() }))}
                    hideControls
                />
            </Flex>

            <Flex align={"center"} gap={10}>
                <NumberInput
                    label={t('scheme.redactor.panelOptions.setFigure.circle.radius')}
                    value={circleStyles.r}
                    onChange={(value) => setCircleStyles((prev) => ({ ...prev, r: value.toString() }))}
                    hideControls
                />
                <ColorInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeFill')}
                    value={circleStyles.fill}
                    onChange={(value) => setCircleStyles((prev) => ({ ...prev, fill: value }))}
                    leftSection={
                        <Tooltip label={t('scheme.redactor.panelOptions.setFigure.polilyne.deleteFill')}>
                            <ActionIcon onClick={() => setCircleStyles({ ...circleStyles, fill: 'none' })} variant="light" color="red">
                                <X size={16} />
                            </ActionIcon>
                        </Tooltip>
                    }
                />
            </Flex>

            <Flex align={"center"} gap={10}>
                <ColorInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeColor')}
                    value={circleStyles.stroke}
                    onChange={(color) => setCircleStyles(prev => ({ ...prev, stroke: color }))}
                />
                <NumberInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeWidth')}
                    value={circleStyles.strokeWidth}
                    onChange={(value) => setCircleStyles(prev => ({ ...prev, strokeWidth: Number(value) || 0 }))}
                />
            </Flex>
        </>
    );
};

export default CircleStylesPanel;
