import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/authorization/Login";
import Prices from "./pages/events/schema/Prices";
import Users from "./pages/users/Users";
import Entity from "./pages/dictionary/entity/Entity";
import Building from "./pages/dictionary/building/Building";
import Hall from "./pages/dictionary/hall/Hall";
import Profile from "./pages/profile/Profile";
import BoxOffice from "./pages/dictionary/box-office/BoxOffice";
import Settings from "./pages/settings/Settings";
import Reports from "./pages/reports/Reports";
import Statistics from "./pages/statistics/Statitics";
import { LoadingOverlay } from "@mantine/core";
import TicketSales from "./pages/reports/TicketSales/TicketSales";
import ZoneSales from "./pages/reports/ZoneSales/ZoneSales";
import CashiersSalesAdmin from "./pages/reports/CashierSales/CashiersSalesAdmin";
import ByPeriod from "./pages/reports/ByPeriod/ByPeriod";
import BuyWidget from "./pages/buy-widget/BuyWidget";
import PriceList from "./pages/reports/PriceList/PriceList";
import SoldTickets from "./pages/management/orders/orders";
import TicketValidation from "./pages/ticket-validation/ticket-validation";
import OrdersInternet from "./pages/management/orders-internet/orders-internet";
import SeatValidate from "./pages/management/seat-validate/seat-validate";
import ContentCategory from "./pages/web/content-category/ContentCategory";
import WebContent from "./pages/web/content/WebContent";
import WebContentForm from "./pages/web/content/WebContentForm";
import { roleCheck } from "./utils/roleCheck";
import ValidatedTickets from "./pages/reports/ValidatedTickets/ValidatedTickets";
import SchemeList from "./pages/dictionary/scheme/SchemeList";
import SchemeViewEdit from "./pages/dictionary/scheme/SchemeViewEdit/SchemeViewEdit";

const Home = React.lazy(() => import("./pages/home/Home"));
const Sales = React.lazy(() => import("./pages/sales/Sales"));
const Events = React.lazy(() => import("./pages/events/Events"));
const Content = React.lazy(() => import("./pages/content/Content"));
export const useRoutes = (isAuth: boolean, role?: string[] | null) => {
    const isInspector = roleCheck(role, ["ticket_inspectors"]) && role?.length === 1;
    if (isAuth) {
        return (
            <Routes>
                <Route path={"/"} element={
                    <Suspense fallback={<LoadingOverlay visible={true} />}>
                        {isInspector ? (<SeatValidate />) : (<Home />)}
                    </Suspense>
                } />
                <Route path={"/events"} element={
                    <Suspense fallback={<LoadingOverlay visible={true} />}>
                        <Events />
                    </Suspense>
                } />
                <Route path={"/events/schema/prices/:id"} element={<Prices />} />
                <Route path={"/events/schema/sales/:id"} element={
                    <Suspense fallback={<LoadingOverlay visible={true} />}>
                        <Sales />
                    </Suspense>
                } />
                <Route path={"/events/content/:id"} element={
                    <Suspense fallback={<LoadingOverlay visible={true} />}>
                        <Content />
                    </Suspense>
                } />
                <Route path={"/users"} element={<Users />} />
                <Route path={"/profile"} element={<Profile />} />
                <Route path={"/settings"} element={<Settings />} />
                <Route path={"/reports"} element={<Reports />} />
                <Route path={"/statistics"} element={<Statistics />} />
                <Route path={"/dictionary/entity"} element={<Entity />} />
                <Route path={"/dictionary/buildings"} element={<Building />} />
                <Route path={"/dictionary/halls"} element={<Hall />} />
                <Route path={"/dictionary/box-offices"} element={<BoxOffice />} />
                <Route path={"/dictionary/schemes"} element={<SchemeList />} />
                <Route path={"/dictionary/schemes/:id"} element={<SchemeViewEdit />} />
                <Route path={"/reports/ticket-sales"} element={<TicketSales />} />
                <Route path={"/reports/prices"} element={<ZoneSales />} />
                <Route path={"/reports/cashiers-sales"} element={<CashiersSalesAdmin />} />
                <Route path={"/reports/by-period"} element={<ByPeriod />} />
                <Route path={"/reports/price-list"} element={<PriceList />} />
                <Route path={"/reports/validated-tickets"} element={<ValidatedTickets />} />
                <Route path={"/management/orders-internet"} element={<OrdersInternet />} />
                <Route path={"/management/orders-cashier"} element={<SoldTickets />} />
                <Route path={"/buy-widget/:id"} element={<BuyWidget />} />
                <Route path={"/management/seat-validate"} element={<SeatValidate />} />
                <Route path={"/management/ticket-validation/:id"} element={<TicketValidation />} />
                <Route path={"/web/content/query"} element={<WebContent />} />
                <Route path={"/web/content/:id"} element={<WebContentForm />} />
                <Route path={"/web/content/create"} element={<WebContentForm />} />
                <Route path={"/web/content-category"} element={<ContentCategory />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path={"/"} element={<Login />} />
            <Route path={"/buy-widget/:id"} element={<BuyWidget />} />
        </Routes>
    );
};
