import { Flex, Textarea, ColorInput, Tooltip, ActionIcon, NumberInput, Button, Title } from "@mantine/core";
import { ArrowLeft, ArrowRight, X } from "lucide-react";
import PolylineEditor from "../../../../modal/PolilyneEditor";
import { PolylineStyles } from '../../../interfaces/SchemeEdit.interface';

interface PolylineEditorPanelProps {
    points: string;
    setPoints: (value: string) => void;
    polylineStyles: PolylineStyles;
    setPolylineStyles: (styles: PolylineStyles) => void;
    t: (key: string) => string;
}

const PolylineEditorPanel = ({
    points,
    setPoints,
    polylineStyles,
    setPolylineStyles,
    t
}: PolylineEditorPanelProps) => {
    const getPolylineCenter = (points: string) => {
        const coords = points.trim().split(/\s+/).map(p => p.split(',').map(Number));

        const xSum = coords.reduce((sum, [x]) => sum + x, 0);
        const ySum = coords.reduce((sum, [, y]) => sum + y, 0);

        const cx = xSum / coords.length;
        const cy = ySum / coords.length;

        return { cx, cy };
    };

    const rotatePolyline = (points: string, cx: number, cy: number, angle: number) => {
        const radians = (Math.PI / 180) * angle;

        const rotatedPoints = points.trim().split(/\s+/).map(p => {
            const [x, y] = p.split(',').map(Number);

            // Rotate point around (cx, cy)
            const newX = cx + (x - cx) * Math.cos(radians) - (y - cy) * Math.sin(radians);
            const newY = cy + (x - cx) * Math.sin(radians) + (y - cy) * Math.cos(radians);

            return `${newX.toFixed(3)},${newY.toFixed(3)}`;
        });

        return rotatedPoints.join(' ');
    };

    const rotateShape = (angle: number) => {
        const { cx, cy } = getPolylineCenter(points);
        setPoints(rotatePolyline(points, cx, cy, angle));
    };

    return (
        <>
            <Title order={6}>{t('scheme.redactor.panelOptions.setFigure.rotateFigure')}</Title>
            <Flex gap={10}>
                <Button
                    size="compact-sm"
                    onClick={() => rotateShape(-10)}
                >
                    <ArrowLeft />
                </Button>
                <Button
                    size="compact-sm"
                    onClick={() => rotateShape(10)}
                >
                    <ArrowRight />
                </Button>
            </Flex>

            <Flex direction={"column"} w={"100%"}>
                <Textarea
                    rows={10}
                    w={"100%"}
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.points')}
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    radius={0}
                />
                <PolylineEditor
                    points={points}
                    polylineStyles={polylineStyles}
                    onPointsChange={setPoints}
                />
            </Flex>

            <Flex gap={10}>
                <ColorInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeColor')}
                    value={polylineStyles.stroke || ''}
                    onChange={(value) => setPolylineStyles({ ...polylineStyles, stroke: value || '' })}
                />

                <ColorInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeFill')}
                    value={polylineStyles.fill || 'none'}
                    onChange={(value) => setPolylineStyles({ ...polylineStyles, fill: value || 'none' })}
                    defaultValue='none'
                    leftSection={
                        <Tooltip label={t('scheme.redactor.panelOptions.setFigure.polilyne.deleteFill')}>
                            <ActionIcon onClick={() => setPolylineStyles({ ...polylineStyles, fill: 'none' })} variant="light" color="red">
                                <X size={16} />
                            </ActionIcon>
                        </Tooltip>
                    }
                />
            </Flex>

            <NumberInput
                w={'50%'}
                label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeWidth')}
                value={polylineStyles.strokeWidth}
                onChange={(value) => setPolylineStyles({ ...polylineStyles, strokeWidth: Number(value) })}
                hideControls
            />
        </>
    );
};

export default PolylineEditorPanel;
