import { useState, useMemo } from 'react';

// Helper function to recursively search through an object
const deepSearch = (obj: any, searchString: string): boolean => {
    if (typeof obj === 'string') {
        return obj.toLowerCase().includes(searchString.toLowerCase());
    }
    if(typeof obj === 'number') {
        return String(obj).toLowerCase().includes(searchString.toLowerCase());
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some((value) => deepSearch(value, searchString));
    }

    return false;
};

// Custom hook to filter the array
const useListSearch = <T extends object>(array: T[], searchString: string): T[] => {
    const filteredArray = useMemo(() => {
        if (!searchString) return array;

        return array.filter((item) => deepSearch(item, searchString));
    }, [array, searchString]);

    return filteredArray;
};

export default useListSearch;