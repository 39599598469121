import { PrinterIcon } from "lucide-react";
import { ActionIcon, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const ReportPrint = ({ handlePrint }: { handlePrint: () => void }) => {
    const { t } = useTranslation();
    return (
        <>
            <Tooltip label={t("events.tooltip.print")}>
                <ActionIcon
                    style={{ position: 'absolute', right: 10, top: 20 }}
                    variant={"transparent"} ml={10} mr={10} onClick={handlePrint}>
                    <PrinterIcon />
                </ActionIcon>
            </Tooltip>

            <div id={"print_timestamp"}>
                {dayjs(new Date()).format("DD-MM-YYYY HH:mm")}
            </div>
        </>
    );
};
export default ReportPrint;