import {api} from "../app/api";
import {IEventShort} from "./event";
import {IUserShort} from "./user";
import {MediaUrl} from "./files";

type EventContentCreate = {
    language: 'en' | 'ro' | 'ru',
    slug?: string,
    slider?: boolean,
    description?: string,
    announcement?: string,
    attributes?: any,
    event: number,
    image?: number | null,
    thumbnail?: number
}

type EventContentRead = {
    id: number,
    event: IEventShort,
    author: IUserShort,
    modifier: IUserShort,
    image: MediaUrl,
    thumbnail: MediaUrl,
    created: string,
    modified: string,
    language: 'en' | 'ro' | 'ru',
    slug?: string,
    slider?: boolean,
    description?: string,
    announcement?: string,
    attributes?: any
}

type QueryParams = {
    event?: number,
    language?: 'ro' | 'ru' | 'en',
    slug?: string,
    ordering?: string,
    page?: string,
    pageSize?: string
}

export const eventContentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEventContent: build.query<{ count: number, next: boolean, previous: boolean, results: EventContentRead[] }, QueryParams>({
            query: (payload) => ({
                url: "/event/admin/events-content/",
                method: "GET",
                params: payload
            }),
            providesTags: ["EventContent"]
        }),
        createEventContent: build.mutation<any, EventContentCreate>({
            query: (payload) => ({
                url: "/event/admin/events-content/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: "EventContent"}]
        }),
        updateEventContent: build.mutation<any, EventContentCreate & { id: number }>({
            query: (payload) => ({
                url: `/event/admin/events-content/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{type: "EventContent"}]
        }),
        removeEventContent: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/event/admin/events-content/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "EventContent" }]
        }),
        readEventContent: build.query<EventContentRead, { id?: number | string }>({
            query: (params) => ({
                url: `/event/admin/events-content/${params.id}`
            }),
            providesTags: ["EventContent"]
        }),
    })
});

export const {
    useCreateEventContentMutation,
    useUpdateEventContentMutation,
    useReadEventContentQuery,
    useGetEventContentQuery,
    useRemoveEventContentMutation,
    useLazyReadEventContentQuery
} = eventContentApi;
