import { SectorProperties, TextToInsert } from "../interfaces/SchemeEdit.interface";
import { setSectorSelected, updateSectorData } from "./SchemeFunctionsHelper";

export const handleUndo = (
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>,
    setRedoStack: React.Dispatch<React.SetStateAction<any[]>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setText: React.Dispatch<React.SetStateAction<any>>,
    undoStack: any[],
    data: any
) => {
    if (undoStack.length === 0) return;

    // Add current state to redo stack
    setRedoStack(prevRedoStack => [data, ...prevRedoStack]);

    const lastState = undoStack[undoStack.length - 1];

    // Remove the last state from the undo stack
    setUndoStack(undoStack.slice(0, undoStack.length - 1));

    setText(lastState.properties.text);
    setData(lastState);
};

export const handleRedo = (
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>,
    setRedoStack: React.Dispatch<React.SetStateAction<any[]>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setText: React.Dispatch<React.SetStateAction<any>>,
    undoStack: any[],
    redoStack: any[],
    data: any
) => {
    if (redoStack.length === 0) return;

    const lastUndoneState = redoStack[0];

    // Move the current state to the undo stack before redoing
    setUndoStack([...undoStack, data]);

    // Remove the first state from redo stack
    setRedoStack(redoStack.slice(1));

    setText(lastUndoneState.properties.text);
    setData(lastUndoneState);
};

export const handleSchemaActionClick = (
    e: React.MouseEvent<SVGSVGElement>,
    data: any,
    editMode: boolean,
    undoStack: any[],
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setSelectedKey: React.Dispatch<React.SetStateAction<string>>,
    setText: React.Dispatch<React.SetStateAction<any>>,
    deleteCircle: boolean,
    dotInsertMode: boolean,
    textInsertMode: boolean,
    textToInsert: TextToInsert,
    setTextToInsert: React.Dispatch<React.SetStateAction<TextToInsert>>,
) => {
    if (!data || !editMode) return;
    if (deleteCircle || (!dotInsertMode && !textInsertMode)) return;

    const svgElement = e.target as SVGSVGElement;
    const svgRect = svgElement.getBoundingClientRect();
    const svgCTM = svgElement.getScreenCTM();
    if (!svgCTM) return;

    const mouseX = e.clientX - svgRect.left;
    const mouseY = e.clientY - svgRect.top;
    const adjustedX = mouseX / svgCTM.a;
    const adjustedY = mouseY / svgCTM.d;
    const fineTuneX = adjustedX - 20;
    const fineTuneY = adjustedY + 1;

    const newData = { ...data, data: { ...data.data } };

    if (dotInsertMode) {
        setUndoStack([...undoStack, data]);

        const newKey = `null:1:1`;
        newData.data[newKey] = { rect: { x: fineTuneX.toString(), y: fineTuneY.toString() } };
        setData(newData);
        setSelectedKey(newKey);
        return;
    }

    if (textInsertMode) {
        textToInsert.rect!.x = fineTuneX.toString();
        textToInsert.rect!.y = fineTuneY.toString();

        if (!textToInsert || Object.keys(textToInsert).length === 0 || textToInsert.properties!.text === '') {
            return;
        }

        setUndoStack([...undoStack, data]);
        console.error(textToInsert)
        setData((prevData: { properties: { text: any; }; }) => ({
            ...prevData,
            properties: {
                ...prevData.properties,
                text: [...(prevData.properties?.text || []), textToInsert]
            }
        }));
        setText((prevText: any) =>
            Array.isArray(prevText)
                ? [...prevText, textToInsert]
                : [textToInsert]
        );
        setTextToInsert(() => ({
            properties: {
                text: '',
                type: 'text',
            },
            rect: { x: '', y: '' },
            styles: {},
        }));
        setSelectedKey('');

        return;
    }
};

export const HandleonSeatClick = (
    id: any,
    key: string,
    editMode: boolean,
    selectItemMode: boolean,
    dotInsertMode: boolean,
    deleteCircle: boolean,
    data: any,
    addRowsColsToSector: SectorProperties,
    setSectorProperties: React.Dispatch<React.SetStateAction<SectorProperties>>,
    setSelectedKey: React.Dispatch<React.SetStateAction<string>>,
    setSelectedDots: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    undoStack: any[],
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>,
    deSelectAll: () => void
) => {
    if (!data || !key || !editMode) {
        return;
    }

    if (selectItemMode) {
        setSectorProperties({ readonly: true });
        setSelectedKey(key);
        setSectorSelected(key, setSelectedDots, data, setData, deSelectAll);

        return;
    } else {
        const newData = JSON.parse(JSON.stringify(data));
        if (!newData.data) {
            return;
        }

        if (dotInsertMode) {
            setSectorProperties({ readonly: false })
            if (addRowsColsToSector) {
                updateSectorData(addRowsColsToSector, newData, key, setData, setSelectedKey);
            }

            return;
        }

        if (deleteCircle) {
            if (newData.data && newData.data[key]) {
                setUndoStack([...undoStack, data]);

                delete newData.data[key];
                setData(newData);

                setSelectedKey('');
            } else {
                console.error(`Seat with key ${key} not found`);
            }

            return;
        }
    }
};