import React, { useEffect, useState } from 'react';
import { Button, Group, Flex, Select, Divider, Title } from '@mantine/core';
import { toNumber } from 'lodash';
import RectStylesPanel from './shapes/RectStylesPanel';
import PolylineEditorPanel from './shapes/PolylineEditorPanel';
import { CircleStyles, PolylineStyles, RectStyles } from '../../interfaces/SchemeEdit.interface';
import CircleStylesPanel from './shapes/CircleStylesPanel';

type figuresType = 'polyline' | 'rect' | 'circle';

interface Props {
    px?: string;
    selectedKeyPolilyne: string;
    selectedKeyRect: string;
    selectedKeyCustomCircle: string;
    setSelectedKeyPolilyne: React.Dispatch<React.SetStateAction<string>>;
    setSelectedKeyRect: React.Dispatch<React.SetStateAction<string>>;
    setSelectedKeyCustomCircle: React.Dispatch<React.SetStateAction<string>>;
    undoStack: any[];
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    setText: React.Dispatch<React.SetStateAction<any>>;
    t: (key: string) => string;
}

export const InsertShape: React.FC<Props> = ({
    px,
    selectedKeyPolilyne,
    selectedKeyRect,
    selectedKeyCustomCircle,
    setSelectedKeyCustomCircle,
    setSelectedKeyRect,
    setSelectedKeyPolilyne,
    undoStack,
    setUndoStack,
    data,
    setData,
    setText,
    t
}) => {
    const [shapeType, setShapeType] = useState<figuresType>('polyline');
    const [shapeInsertMode, setShapeInsertMode] = useState(true);
    const [points, setPoints] = useState("50,330 70,330 168,200 188,220 380,220 400,200 420,220 615,220 635,200 655,220 850,220 870,200 965,330 985,330");

    const figures = [
        {
            value: 'polyline',
            label: t('scheme.redactor.panelOptions.setFigure.polilyne.title')
        },
        {
            value: 'rect',
            label: t('scheme.redactor.panelOptions.setFigure.rect.title')
        },
        {
            value: 'circle',
            label: t('scheme.redactor.panelOptions.setFigure.circle.title')
        }
    ]
    const [polylineStyles, setPolylineStyles] = useState<PolylineStyles>({
        stroke: "gray",
        fill: "none",
        strokeWidth: 3
    });
    const [rectStyles, setRectStyles] = useState<RectStyles>({
        x: "442",
        y: "305",
        rx: "5px",
        ry: "5px",
        fill: "#c5c5c5",
        width: "150px",
        height: "5px",
    });
    const [circleStyles, setCircleStyles] = useState<CircleStyles>({
        x: "100",
        y: "100",
        r: "50",
        stroke: "gray",
        strokeWidth: 3,
        fill: "none",
    });

    useEffect(() => {
        if (selectedKeyPolilyne || selectedKeyRect || selectedKeyCustomCircle) {
            const key = toNumber(selectedKeyPolilyne || selectedKeyRect || selectedKeyCustomCircle);
            const shape = data.properties.text[key];

            setShapeInsertMode(false);
            setShapeType(shape.properties.type);
            console.error(shape)
            switch (shape.properties.type) {
                case 'polyline': {
                    setPoints(shape.rect.points);
                    setPolylineStyles({
                        stroke: shape.styles.stroke,
                        fill: shape.styles.fill,
                        strokeWidth: shape.styles.strokeWidth
                    });
                    break;
                }
                case 'rect': {
                    setRectStyles({
                        x: shape.rect.x,
                        y: shape.rect.y,
                        rx: shape.styles.rx,
                        ry: shape.styles.ry,
                        fill: shape.styles.fill,
                        width: shape.styles.width,
                        height: shape.styles.height,
                    });
                    break;
                }
                case 'circle': {
                    setCircleStyles({
                        x: shape.rect.x,
                        y: shape.rect.y,
                        r: shape.rect.r,
                        fill: shape.styles.fill,
                        stroke: shape.styles.stroke,
                        strokeWidth: shape.styles.strokeWidth,
                    });

                    break;
                }

                default:
                    break;
            }
        } else {
            setShapeInsertMode(true);
        }
    }, [selectedKeyPolilyne, selectedKeyRect, selectedKeyCustomCircle]);

    const generateJson = () => {
        if (typeof data !== 'object') return;

        setUndoStack([...undoStack, data]);

        let newShape: any;

        switch (shapeType) {
            case 'polyline': {
                newShape = {
                    properties: { type: 'polyline' },
                    rect: { points },
                    styles: {
                        stroke: polylineStyles.stroke,
                        strokeWidth: polylineStyles.strokeWidth,
                        fill: polylineStyles.fill,
                    }
                };
                break;
            }
            case 'rect': {
                newShape = {
                    rect: { x: rectStyles.x, y: rectStyles.y },
                    styles: { ...rectStyles },
                    properties: { type: 'rect' }
                };
                break;
            }
            case 'circle': {
                newShape = {
                    rect: { x: circleStyles.x, y: circleStyles.y, r: circleStyles.r },
                    styles: {
                        fill: circleStyles.fill,
                        stroke: circleStyles.stroke,
                        strokeWidth: circleStyles.strokeWidth
                    },
                    properties: { type: 'circle' }
                };
                break;
            }
        }

        if (shapeInsertMode) {
            setData((prevData: { properties: { text: any; }; }) => ({
                ...prevData,
                properties: {
                    ...prevData?.properties,
                    text: [...(prevData?.properties?.text || []), newShape]
                }
            }));

            setText((prevText: any) => [...(prevText || []), newShape]);
        } else {
            const updatedText = [...(data?.properties?.text || [])];
            updatedText[toNumber(selectedKeyPolilyne || selectedKeyRect || selectedKeyCustomCircle)] = newShape;

            setData((prevData: { properties: { text: any; }; }) => ({
                ...prevData,
                properties: {
                    ...prevData?.properties,
                    text: updatedText
                }
            }));

            setText(updatedText);
        }
    };

    return (
        <>
            <Divider mt={15} mb={10} />

            <Group px={px}>
                <Flex w={"100%"} direction={"column"}>
                    <Title order={3} mb={5}>{t('scheme.redactor.panelOptions.setFigure.title')}</Title>

                    <Select
                        w={"100%"}
                        label={!shapeInsertMode ? null : t('scheme.redactor.panelOptions.setFigure.selectFigureType')}
                        value={shapeType}
                        onChange={(value) => {
                            setShapeType(value as figuresType)
                            setSelectedKeyCustomCircle('');
                            setSelectedKeyPolilyne('');
                            setSelectedKeyRect('');

                            setShapeInsertMode(true);
                        }}
                        data={figures}
                        readOnly={!shapeInsertMode}
                    />
                </Flex>

                {
                    shapeType === 'polyline' && (
                        <PolylineEditorPanel
                            t={t}
                            polylineStyles={polylineStyles}
                            setPolylineStyles={setPolylineStyles}
                            points={points}
                            setPoints={setPoints}
                        />
                    )
                }

                {
                    shapeType === 'rect' && (
                        <RectStylesPanel
                            rectStyles={rectStyles}
                            t={t}
                            setRectStyles={setRectStyles}
                        />
                    )
                }

                {
                    shapeType === 'circle' && (
                        <CircleStylesPanel
                            circleStyles={circleStyles}
                            setCircleStyles={setCircleStyles}
                            t={t}
                        />
                    )
                }

                <Button onClick={generateJson}>
                    {
                        shapeInsertMode
                            ? t('scheme.redactor.panelOptions.setFigure.insertBtn')
                            : t('scheme.redactor.panelOptions.setFigure.updateBtn')
                    }
                </Button>
            </Group>
        </>
    );
};
