import { api } from "../app/api";
import { IUser } from "./user";

export interface IContentCategory {
    code: string;
    name?: string;
    content?: {
        id?: number,
        value: string,
        language: string
    }[]
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IContentResponse {
    count: number;
    next: any;
    previous: any;
    results: IContentCategory[];
}

export interface IContentCategoryRead {
    code: string,
    name: string,

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export const webContentCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getContentCategories: build.query<IContentResponse, {
            ordering?: string,
            page?: number,
            page_size?: number
        }>({
            query: (params) => ({
                url: `/content/admin/categories/`,
                params: {
                    page: params.page,
                    page_size: params.page_size
                }
            }),
            providesTags: (result, error, tag) => [{ type: "ContentCategory", tag }]
        }),
        createContentCategory: build.mutation<any, IContentCategory>({
            query: (payload) => ({
                url: "/content/admin/categories/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "ContentCategory" }]
        }),
        updateContentCategory: build.mutation<any, {code: string, name: string}>({
            query: (payload) => ({
                url: `/content/admin/categories/${payload.code}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: (result) => (result ? [{ type: "ContentCategory" }] : ['ERROR'])
        }),
        readContentCategory: build.query<IContentCategoryRead, { code?: string }>({
            query: (params) => ({
                url: `/content/admin/categories/${params.code}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "ContentCategory", tag }],
        }),
        removeContentCategory: build.mutation<any, { code: string }>({
            query: (payload) => ({
                url: `/content/admin/categories/${payload.code}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "ContentCategory" }]
        })
    })
});

export const {
    useGetContentCategoriesQuery,
    useLazyReadContentCategoryQuery,
    useReadContentCategoryQuery,
    useUpdateContentCategoryMutation,
    useRemoveContentCategoryMutation,
    useCreateContentCategoryMutation
} = webContentCategoryApi;
