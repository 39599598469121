import React, { useState, useEffect } from 'react';
import { Title, Flex, NumberInput, SegmentedControl, Tooltip, Divider, Button, Space, Paper, Slider } from '@mantine/core';
import HallPartsSelector from './HallPartsSelector';
import { SectorProperties } from '../interfaces/SchemeEdit.interface';
import { toNumber } from 'lodash';
import { Calculator, IterationCcwIcon, RotateCcwIcon } from 'lucide-react';
import { IHallParts } from '../../../../../api/hall';

interface SetSectorProps {
    selectItemMode: boolean;
    px?: string;
    undoStack: any[];
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>;
    selectedDots: Record<string, any>;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>,
    selectedKey: string;
    hallParts?: IHallParts;
    hallPartsLoading: boolean;
    sectorProperties: SectorProperties;
    setSectorProperties: React.Dispatch<React.SetStateAction<SectorProperties>>;
    t: (key: string) => string;
}

const SetSector: React.FC<SetSectorProps> = ({
    selectItemMode,
    px,
    undoStack,
    setUndoStack,
    selectedDots,
    data,
    setData,
    selectedKey,
    hallParts,
    hallPartsLoading,
    sectorProperties,
    setSectorProperties,
    t
}) => {
    const [selectedHallPart, setSelectedHallPart] = useState<string | null>(null);
    const [stepX, setStepX] = useState<number>(20);
    const [stepY, setStepY] = useState<number>(20);
    const [sectorRows, setSectorRows] = useState<number>(0);
    const [sectorCols, setSectorCols] = useState<number>(0);
    const directionLeftRightOptions = {
        LeftToRight: t('scheme.redactor.panelOptions.setSector.directionOptions.leftToRight'),
        RightToLeft: t('scheme.redactor.panelOptions.setSector.directionOptions.rightToLeft')
    };
    const [directionLeftRight, setDirectionLeftRight] = useState<string>(Object.keys(directionLeftRightOptions)[0]);

    const directionUpDownOptions = {
        UpToDown: t('scheme.redactor.panelOptions.setSector.directionOptions.UpToDown'),
        DownToUp: t('scheme.redactor.panelOptions.setSector.directionOptions.DownToUp')
    };
    const [directionUpDown, setDirectionUpDown] = useState<string>(Object.keys(directionUpDownOptions)[0]);

    const [halfRotationDegrees, setHalfRotationDegrees] = useState<number>(25);
    const rotateSeats = (direction: "left" | "right") => {
        // Create a deep copy of the data
        const newData = JSON.parse(JSON.stringify(data));

        // Rotation angle in radians for left and right
        const angle = Math.PI / halfRotationDegrees; // Convert the degrees to radians
        const rotateMatrix = direction === "left" ? angle : -angle;

        // Find the pivot seat from selectedDots (the seat that ends with :1:1)
        const pivotKey = selectedDots.find((key: string) => key.endsWith(":1:1"));

        if (!pivotKey) {
            console.error("Pivot seat not found.");
            return;
        }

        const pivotSeat = newData.data[pivotKey];

        if (pivotSeat) {
            const pivotX = parseFloat(pivotSeat.rect.x);
            const pivotY = parseFloat(pivotSeat.rect.y);

            if (isNaN(pivotX) || isNaN(pivotY)) return;

            selectedDots.forEach((selectedKey: string) => {
                const seat = newData.data[selectedKey];

                if (seat) {
                    let x = parseFloat(seat.rect.x);
                    let y = parseFloat(seat.rect.y);

                    // Ensure the x and y are valid numbers
                    if (isNaN(x) || isNaN(y)) return;

                    // Step 1: Translate the seat to the origin (relative to pivot)
                    let translatedX = x - pivotX;
                    let translatedY = y - pivotY;

                    // Step 2: Rotate the seat around the pivot (origin)
                    let rotatedX = translatedX * Math.cos(rotateMatrix) - translatedY * Math.sin(rotateMatrix);
                    let rotatedY = translatedX * Math.sin(rotateMatrix) + translatedY * Math.cos(rotateMatrix);

                    // Step 3: Translate the seat back to its original position
                    let finalX = rotatedX + pivotX;
                    let finalY = rotatedY + pivotY;

                    seat.rect.x = finalX.toString();
                    seat.rect.y = finalY.toString();
                    newData.data[selectedKey] = seat;
                } else {
                    console.error(`Seat ${selectedKey} does not exist in data.`);
                }
            });

            setData((prev: any) => ({ ...prev, data: newData.data }));
        } else {
            console.error("Pivot seat does not exist.");
        }
    };

    const reCalculateSeats = () => {
        setUndoStack([...undoStack, data]);

        const newData = JSON.parse(JSON.stringify(data));

        selectedDots.forEach((selectedKey: string) => {
            const [section, row] = selectedKey.split(":");

            const rowSeats = Object.keys(newData.data)
                .filter(seatKey => seatKey.startsWith(`${section}:${row}:`))
                .sort((a, b) => parseInt(a.split(":")[2], 10) - parseInt(b.split(":")[2], 10));

            rowSeats.forEach((seatKey, index) => {
                const newSeatKey = `${section}:${row}:${index + 1}`;

                if (seatKey !== newSeatKey) {
                    newData.data[newSeatKey] = newData.data[seatKey];
                    delete newData.data[seatKey];
                }
            });
        });

        setData((prev: any) => ({ ...prev, data: newData.data }));
    };

    const [curveValue, setCurveValue] = useState(0);
    const handleCurveChange = (value: number) => {
        const newData = JSON.parse(JSON.stringify(data));

        // Step 1: Get keys and dynamically calculate grid dimensions
        const keys = selectedDots;
        const rowsSet = new Set();
        const colsSet = new Set();
        const nameParts = new Set();

        keys.forEach((key: string) => {
            const parts = key.split(':');
            nameParts.add(parts[0]);
            rowsSet.add(parts[1]);
            colsSet.add(parts[2]);
        });

        const rows = Array.from(rowsSet);
        const cols = Array.from(colsSet);
        const namePart = Array.from(nameParts)[0];

        // Step 2: Adjust each row based on the number of dots
        rows.forEach((row) => {
            // Identify the range of columns in the current row
            const rowKeys = cols.map((col) => `${namePart}:${row}:${col}`);
            const rowRects = rowKeys.map((key) => newData.data[key]?.rect);

            // Skip if row does not contain any data
            if (!rowRects || rowRects.length === 0) return;

            const rowLength = rowRects.length;

            // Handle case when row length is even or odd
            const semiCircleRadius = Math.abs(value) * 2; // Use absolute value for depth regardless of sign
            const scalingFactor = 1.5; // Adjust the scaling factor to make the semi-circle deeper

            // Step 3: Apply movement and depth logic
            rowRects.forEach((rect, idx) => {
                if (rect) {
                    const angle = (Math.PI / (rowLength - 1)) * idx; // Distribute points along the semi-circle
                    const yOffset = Math.sin(angle) * semiCircleRadius * scalingFactor; // Apply curve depth
                    const xOffset = (idx - Math.floor(rowLength / 2)) * (Math.abs(value) / 10); // Spread the points horizontally

                    // If positive value, move up (subtract yOffset), else move down (add yOffset)
                    rect.y = (parseFloat(rect.y || '0') - (value > 0 ? yOffset : -yOffset)).toString();  // Apply vertical movement
                    rect.x = (parseFloat(rect.x || '0') + xOffset).toString();  // Apply horizontal offset
                }
            });
        });

        // Step 4: Update the modified data
        setData(newData);
    };

    useEffect(() => {
        const nameParter = selectedKey.split(":")[0];
        if (nameParter === "null" && !selectItemMode) {
            return;
        }

        const matchingParters = Object.keys(data.data).filter(key => key.includes(nameParter));
        if (matchingParters.length > 0) {
            const lastMatch = matchingParters[matchingParters.length - 1];
            const firstMatch = matchingParters[0];
            const [_, rowIndexLast, colIndexLast] = lastMatch.split(":").map(toNumber);
            const [__, rowIndexFirst, colIndexFirst] = firstMatch.split(":").map(toNumber);

            const deltaX = data.data[lastMatch].rect.x - data.data[firstMatch].rect.x;
            const deltaY = data.data[lastMatch].rect.y - data.data[firstMatch].rect.y;
            const calculatedStepX = deltaX / Math.abs(colIndexLast - colIndexFirst || 1);
            const calculatedStepY = deltaY / Math.abs(rowIndexLast - rowIndexFirst || 1);

            setSelectedHallPart(nameParter);
            setSectorRows(rowIndexLast);
            setSectorCols(colIndexLast);
            setStepX(Math.abs(calculatedStepX));
            setStepY(Math.abs(calculatedStepY));
        }
    }, [selectItemMode]);

    useEffect(() => {
        setSectorProperties(prev => ({
            ...prev,
            nameParter: selectedHallPart || '',
            rows: sectorRows,
            cols: sectorCols,
            stepX: stepX,
            stepY: stepY,
            sectorOrientationLeftRight: directionLeftRight,
            sectorOrientationUpDown: directionUpDown
        }));

    }, [selectedHallPart, stepX, stepY, sectorRows, sectorCols, directionLeftRight, setSectorProperties]);

    return (
        <>
            {
                (selectedDots && Object.keys(selectedDots).length !== 0) ? (
                    <>
                        <Divider my={15} />

                        <Title px={px} mb={5} order={4}>{t("scheme.redactor.panelOptions.setSector.actions.title")}</Title>
                        <Space h="xs" />

                        <Title px={px} order={5}>{t("scheme.redactor.panelOptions.setSector.setSectorSemiCircle")}</Title>
                        <Slider
                            my={10}
                            px={30}
                            value={curveValue}
                            onChange={(value) => {
                                setCurveValue(value);
                                handleCurveChange(value);
                            }}
                            w={"100%"}
                            min={-30}
                            max={30}
                            step={5}
                            label={curveValue}
                        />

                        <Title px={px} order={5}>{t("scheme.redactor.contextMenu.labels.rotationTitle")}</Title>
                        <Space h="xs" />

                        <Flex px={px} gap={10}>
                            <Button size="sm" onClick={() => rotateSeats('left')}>
                                <IterationCcwIcon />
                            </Button>
                            <Tooltip label={t("scheme.redactor.contextMenu.labels.rotationAngleInRadians")}>
                                <NumberInput
                                    size="sm"
                                    min={1}
                                    value={halfRotationDegrees}
                                    onChange={(value) => setHalfRotationDegrees(toNumber(value))}
                                />
                            </Tooltip>
                            <Button size="sm" onClick={() => rotateSeats('right')}>
                                <RotateCcwIcon />
                            </Button>
                        </Flex>

                        <Space h="md" />
                        <Divider variant="dashed" />
                        <Space h="sm" />
                        <Flex px={px} justify={"center"}>
                            <Tooltip label={t("scheme.redactor.contextMenu.buttons.recalculateSelectDots")}>
                                <Button
                                    size="sm"
                                    onClick={reCalculateSeats}
                                    variant="light"
                                    color="orange"
                                >
                                    <Calculator size={22} />
                                </Button>
                            </Tooltip>
                        </Flex>
                    </>
                ) : null
            }

            <Divider my={15} />

            <Paper px={px}>
                <Title mb={5} order={4}>
                    {
                        sectorProperties.readonly ?
                            t('scheme.redactor.panelOptions.setSector.titleReadonly') :
                            t('scheme.redactor.panelOptions.setSector.title')
                    }
                </Title>

                <SegmentedControl
                    w={"100%"}
                    value={directionLeftRight}
                    onChange={setDirectionLeftRight}
                    data={Object.entries(directionLeftRightOptions).map(([value, label]) => ({
                        value,
                        label,
                    }))}
                    readOnly={sectorProperties.readonly}
                    style={{
                        borderRadius: 0
                    }}
                />
                <SegmentedControl
                    w={"100%"}
                    value={directionUpDown}
                    onChange={setDirectionUpDown}
                    data={Object.entries(directionUpDownOptions).map(([value, label]) => ({
                        value,
                        label,
                    }))}
                    readOnly={sectorProperties.readonly}
                    style={{
                        borderRadius: 0
                    }}
                />
                <Space h="sm" />

                <HallPartsSelector
                    selectedHallPart={selectedHallPart!}
                    hallParts={hallParts?.results || []}
                    hallPartsLoading={hallPartsLoading}
                    onChange={setSelectedHallPart}
                    t={t}
                    readOnly={sectorProperties.readonly}
                />

                <Space h="md" />
                <Flex gap={10}>
                    <NumberInput
                        min={10}
                        label={t('scheme.redactor.panelOptions.setSector.stepsX')}
                        placeholder={t('scheme.redactor.panelOptions.setSector.stepsX')}
                        value={stepX}
                        onChange={(value) => setStepX(Number(value) || 10)}
                        readOnly={sectorProperties.readonly}
                    />

                    <NumberInput
                        min={10}
                        label={t('scheme.redactor.panelOptions.setSector.stepsY')}
                        placeholder={t('scheme.redactor.panelOptions.setSector.stepsX')}
                        value={stepY}
                        onChange={(value) => setStepY(Number(value) || 10)}
                        readOnly={sectorProperties.readonly}
                    />
                </Flex>

                <Space h="xs" />
                <Flex gap={10}>
                    <NumberInput
                        min={2}
                        label={t('scheme.redactor.panelOptions.setSector.sectorRows')}
                        placeholder="Nr."
                        value={sectorRows}
                        onChange={(value) => setSectorRows(Number(value) || 2)}
                        readOnly={sectorProperties.readonly}
                    />

                    <NumberInput
                        min={2}
                        label={t('scheme.redactor.panelOptions.setSector.sectorColumns')}
                        placeholder="Nr."
                        value={sectorCols}
                        onChange={(value) => setSectorCols(Number(value) || 2)}
                        readOnly={sectorProperties.readonly}
                    />
                </Flex>
            </Paper>
        </>
    );
};

export default SetSector;
