import {api} from "../app/api";

type FileType = 'event_qr' | 'event_ticket' | 'event_content' | 'common_content';

export type FileUpload = {
    type: FileType,
    event?: number,
    convert_image?: string,
    image: File
}

export type MediaUrl = {
    id: number,
    url: string,
    type: FileType
}
export const eventContentApi = api.injectEndpoints({
    endpoints: (build) => ({
        upload: build.mutation<MediaUrl, FileUpload>({
            query: (payload) => {
                let formData = new FormData();
                formData.append('file', payload.image);
                formData.append('event', String(payload.event));
                formData.append('type', payload.type);
                payload.convert_image && formData.append('convert_image', payload.convert_image);

                return {
                    url: "/files/upload/",
                    method: "POST",
                    formData: true,
                    body: formData
                }
            },
            invalidatesTags: [{type: "EventContent"}]
        }),
    })
});

export const {useUploadMutation} = eventContentApi