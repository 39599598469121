import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    Divider,
    Flex,
    LoadingOverlay,
    Paper,
    Table,
    Text,
    TextInput,
    useMantineColorScheme
} from "@mantine/core";
import ReportHeader from "../ReportActions";
import {useAppSelector} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import {useListState, useDebouncedValue} from '@mantine/hooks';
import {ITicket, useLazyGetTicketsQuery} from "../../../api/event";
import {isErrorWithMessage, showErrorMessages} from "../../../app/api";
import useListSearch from "../../../hooks/useListSearch";
import dayjs from "dayjs";

const initialValues = [
    {label: 'Отсканированные', checked: true, key: 'used'},
    {label: 'Проданные', checked: true, key: 'sold'},
    {label: 'Возврат', checked: false, key: 'refunded'},
    {label: 'Резерв', checked: false, key: 'reserved'},
];
const ValidatedTickets = () => {
    const selectedEvent = useAppSelector(state => state.reports.selectedEvent);
    const {t} = useTranslation();
    const {colorScheme} = useMantineColorScheme();
    const [values, handlers] = useListState(initialValues);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [debouncedSearch] = useDebouncedValue(search, 200);

    const [data, setData] = useState<ITicket[]>([])
    const filteredData = useListSearch<ITicket>(data, debouncedSearch);

    const [getTickets] = useLazyGetTicketsQuery();

    useEffect(() => {
        if (selectedEvent) {
            setIsLoading(true)
            getTickets({
                event: selectedEvent,
                status: values.filter(v => v.checked).map(v => v.key),
                page_size: 9999
            }).unwrap().then(res => {
                setData(res.results)
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                if (isErrorWithMessage(e)) {
                    showErrorMessages(e.data);
                }
            });
        }
    }, [selectedEvent, JSON.stringify(values)]);


    const checkBoxes = values.map((value, index) => (
        <Checkbox
            mt="xs"
            mr={33}
            ml={10}
            label={value.label}
            key={value.key}
            checked={value.checked}
            onChange={(event) => handlers.setItemProp(index, 'checked', event.currentTarget.checked)}
        />
    ));

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value)
    }

    return (
        <Box pos={"relative"}>

            <Paper p={10} style={{position: 'relative'}}>
                <ReportHeader
                    showEventSelect
                    dateType={"default"}
                />
                <LoadingOverlay visible={isLoading}/>
            </Paper>
            {selectedEvent && (
                <>
                    <Flex>
                        {checkBoxes}
                    </Flex>
                    <Paper my={10}>
                        <TextInput placeholder={'поиск'} onChange={handleSearch}/>
                    </Paper>
                    <Paper>
                        <Table withColumnBorders={true} withTableBorder={true}
                               highlightOnHover
                        >
                            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                                <Table.Tr>
                                    <Table.Th>
                                        Заказ
                                    </Table.Th>

                                    <Table.Th>
                                        Место
                                    </Table.Th>
                                    <Table.Th>
                                        Цена
                                    </Table.Th>
                                    <Table.Th>
                                        Клиент
                                    </Table.Th>

                                </Table.Tr>
                            </Table.Thead>

                            <Table.Tbody>
                                {filteredData.map(v => {
                                    const split = v.key.split(":");
                                    const label = split[0];
                                    const seatN = split[1];
                                    const rowN = split[2];
                                    return (
                                        <Table.Tr>
                                            <Table.Td>
                                                <Flex justify={'space-between'}>
                                                    <Text size={"sm"}>№ Билета</Text>
                                                    <Text>{v.id}</Text>
                                                </Flex>
                                                <Divider/>
                                                <Flex justify={'space-between'}>
                                                    <Text size={"sm"}>№ Заказа</Text>
                                                    <Text>{v.booking.id}</Text>
                                                </Flex>

                                                <Divider/>
                                                <Text size={"sm"}>Описание <br/>{v.booking.description}</Text>
                                            </Table.Td>
                                            <Table.Td>
                                                <Text size={"sm"}>
                                                    {t(`sales.${label}`)}, &nbsp;
                                                    {t("sales.row")} {seatN},
                                                    &nbsp;{t("sales.seat")} {rowN}</Text>
                                                <Text c={v.status === 'sold' ? 'teal' : 'orange'}>
                                                    {t(`orders.${v.status}`)}
                                                </Text>
                                                <Text>

                                                    {dayjs(v.created).format("DD-MM-YYYY hh:mm")}
                                                </Text>
                                            </Table.Td>
                                            <Table.Td>
                                                <Box>
                                                    <Text>{v.sale.amount} MDL</Text>
                                                    <Divider/>
                                                    <Text fs={'italic'}
                                                          fw={'bold'}>{t(`sales.ticket_log.payment_type.${v.sale.payment_type}`)}</Text>
                                                </Box>
                                            </Table.Td>
                                            <Table.Td>
                                                {v.customer ? (
                                                    <>
                                                        <Text>{v.customer?.first_name} {v.customer?.last_name}</Text>
                                                        <Divider/>
                                                        <Text>
                                                            {v.customer?.email}
                                                        </Text>
                                                        <Divider/>
                                                        <Text>
                                                            {v.customer?.phone}
                                                        </Text>
                                                    </>
                                                ) : <>Касса</>}
                                            </Table.Td>
                                        </Table.Tr>
                                    )
                                })}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </>

            )}
            <LoadingOverlay visible={false}/>
        </Box>
    )
}
export default ValidatedTickets