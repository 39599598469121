import { useEffect, useRef, useState } from 'react';
import { Modal, Button, TextInput, Flex, ActionIcon, Tooltip, Paper, Divider, Grid, SegmentedControl, Accordion } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { CircleOff, MapPinOff, Redo, Trash, Undo } from 'lucide-react';
import React from 'react';

interface Props {
    points: string;
    polylineStyles: any;
    onPointsChange: (newPoints: string) => void;
}

export const PolylineEditor: React.FC<Props> = ({ points, polylineStyles, onPointsChange }) => {
    const { t } = useTranslation();
    const svgRef = useRef<SVGSVGElement>(null);
    const [opened, setOpened] = useState(false);
    const [currentPoints, setCurrentPoints] = useState(points);
    const [history, setHistory] = useState<string[]>([]);
    const [redoStack, setRedoStack] = useState<string[]>([]);
    const [contextMenu, setContextMenu] = useState<{ x: number; y: number; visible: boolean }>({ x: 0, y: 0, visible: false });
    const [mode, setMode] = useState<'insert' | 'move'>('insert');

    const SVG_WIDTH = 1030;
    const SVG_HEIGHT = 750;
    const PADDING = 5;

    useEffect(() => {
        if (points) {
            setCurrentPoints(points);
        } else {
            setCurrentPoints("20,20");
        }
    }, [points]);

    const handlePointClick = (e: React.MouseEvent<SVGSVGElement>) => {
        if (contextMenu.visible) {
            closeContextMenu();
        }
        if (mode !== 'insert' || !svgRef.current) return;

        const svg = svgRef.current;
        const pt = new DOMPoint(e.clientX, e.clientY).matrixTransform(svg.getScreenCTM()?.inverse());

        if (pt.x < PADDING || pt.x > SVG_WIDTH - PADDING || pt.y < PADDING || pt.y > SVG_HEIGHT - PADDING) {
            return;
        }

        setHistory(prev => [...prev, currentPoints]);
        setRedoStack([]);

        setCurrentPoints(prevPoints => {
            const newPoint = `${Math.floor(pt.x)},${Math.floor(pt.y)}`;
            const updatedPoints = prevPoints ? `${prevPoints} ${newPoint}` : newPoint;
            onPointsChange(updatedPoints);
            return updatedPoints;
        });
    };

    const undo = () => {
        if (history.length === 0) return;
        const lastPoints = history[history.length - 1];
        setHistory(prev => prev.slice(0, -1));
        setRedoStack(prev => [currentPoints, ...prev]);
        setCurrentPoints(lastPoints);
        onPointsChange(lastPoints);
    };

    const redo = () => {
        if (redoStack.length === 0) return;
        const nextPoints = redoStack[0];
        setRedoStack(prev => prev.slice(1));
        setHistory(prev => [...prev, currentPoints]);
        setCurrentPoints(nextPoints);
        onPointsChange(nextPoints);
    };

    const clearPoints = () => {
        resetActiveIndex();
        setHistory(prev => [...prev, currentPoints]);
        setCurrentPoints('');
        onPointsChange('');
    };

    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        setContextMenu(
            {
                x: e.clientX - 565,
                y: e.clientY - 54,
                visible: true
            }
        );
    };

    const closeContextMenu = () => {
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const handleInputChange = (index: number, axis: 'x' | 'y', value: string) => {
        const pointsArray = currentPoints.split(' ').map(point => point.split(','));
        pointsArray[index][axis === 'x' ? 0 : 1] = value;

        const updatedPoints = pointsArray.map(point => point.join(',')).join(' ');
        setHistory(prev => [...prev, currentPoints]);
        setCurrentPoints(updatedPoints);
        onPointsChange(updatedPoints);
    };

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const handleCircleClick = (index: number) => {
        if (mode === 'move') {
            setActiveIndex(index);
        }
    };

    const resetActiveIndex = () => {
        setActiveIndex(null);
    };

    const removeLastPoint = () => {
        const pointsArray = currentPoints.split(' ').map(point => point.split(','));

        if (pointsArray.length > 0) {
            pointsArray.pop();

            const updatedPoints = pointsArray.map(point => point.join(',')).join(' ');
            setHistory(prev => [...prev, currentPoints]);
            setCurrentPoints(updatedPoints);
            onPointsChange(updatedPoints);
        }
    };

    const handleMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
        if (mode !== 'move' || !dragging || activeIndex === null || !svgRef.current) return;

        const svg = svgRef.current;
        const pt = new DOMPoint(e.clientX, e.clientY).matrixTransform(svg.getScreenCTM()?.inverse());

        setCurrentPoints(prev => {
            const pointsArray = prev.split(' ').map(p => p.split(','));
            pointsArray[activeIndex] = [`${Math.floor(pt.x)}`, `${Math.floor(pt.y)}`];

            const updatedPoints = pointsArray.map(p => p.join(',')).join(' ');
            onPointsChange(updatedPoints);
            return updatedPoints;
        });
    };

    const handleMouseUp = (e: React.MouseEvent) => {
        if (e.button === 0 && dragging) {
            setDragging(false);
            setHistory(prev => [...prev, currentPoints]);
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button === 0 && activeIndex !== null) {
            setDragging(true);
        }
    };

    const pointArray = currentPoints.split(' ').map(point => point.split(','));

    return (
        <>
            <Button onClick={() => setOpened(true)} w="100%" radius={0}>
                {t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.editPolilyne')}
            </Button>

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.title')}
                size="xl"
                aria-hidden={opened ? 'false' : 'true'}
            >
                <SegmentedControl
                    w={"100%"}
                    radius={0}
                    value={mode}
                    onChange={
                        (val) => {
                            resetActiveIndex();
                            setMode(val as 'insert' | 'move');
                        }
                    }
                    data={[
                        {
                            label: t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.mods.insert'),
                            value: 'insert'
                        },
                        {
                            label: t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.mods.move'),
                            value: 'move'
                        }
                    ]}
                />
                <svg
                    ref={svgRef}
                    viewBox="0 -7 1030 750"
                    width="100%"
                    height="100%"
                    style={{
                        border: '1px solid black',
                        cursor: 'crosshair',
                        backgroundSize: '35px 35px',
                        backgroundImage: `linear-gradient(to right, rgba(128, 128, 128, 0.2) 1px, transparent 1px),
                                          linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 1px, transparent 1px)`,
                        userSelect: 'none', // Prevents selection
                        WebkitUserSelect: 'none', // Safari support
                        MozUserSelect: 'none', // Firefox support
                    }}
                    onClick={handlePointClick}
                    onContextMenu={handleContextMenu}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseDown={handleMouseDown}
                >
                    <polyline points={currentPoints} fill="none" style={polylineStyles} />
                    {pointArray.length > 0 && pointArray.map(([x, y], index) => {
                        const parsedX = parseFloat(x);
                        const parsedY = parseFloat(y);

                        return !isNaN(parsedX) && !isNaN(parsedY) ? (
                            <React.Fragment key={`point-${index}`}>
                                <circle
                                    cx={parsedX}
                                    cy={parsedY}
                                    r={6}
                                    fill={index === activeIndex && mode === 'move' ? 'green' : 'red'}
                                    onClick={() => handleCircleClick(index)}
                                    style={activeIndex === null && mode === 'move' ? { cursor: 'pointer' } : {}}
                                />

                                <text
                                    x={parsedX - 5}
                                    y={parsedY - 12}
                                    fontSize="18px"
                                    strokeWidth="2"
                                    fill={index === activeIndex && mode === 'move' ? 'green' : 'red'}
                                    stroke={index === activeIndex && mode === 'move' ? 'green' : 'red'}
                                >
                                    {index + 1}
                                </text>
                            </React.Fragment>
                        ) : null;
                    })}
                </svg>

                {pointArray.length > 0 && (
                    <Accordion variant="contained">
                        <Accordion.Item value="points-editor">
                            <Accordion.Control>
                                {t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.editPoints')}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Grid>
                                    {pointArray.map(([x, y], index) => (
                                        <Grid.Col span={3} key={`point-${x}-${y}`}>
                                            <Flex
                                                style={{
                                                    border: '1px solid black',
                                                }}
                                            >
                                                <TextInput
                                                    value={x || ''}
                                                    onChange={(e) => handleInputChange(index, 'x', e.target.value)}
                                                    size="xs"
                                                    leftSection={`x${index + 1}:`}
                                                    leftSectionWidth={35}
                                                    radius={0}
                                                />
                                                <TextInput
                                                    leftSection={`y${index + 1}:`}
                                                    leftSectionWidth={35}
                                                    value={y || ''}
                                                    onChange={(e) => handleInputChange(index, 'y', e.target.value)}
                                                    size="xs"
                                                    radius={0}
                                                />
                                            </Flex>
                                        </Grid.Col>
                                    ))}
                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                )}

                {contextMenu.visible && (
                    <Paper
                        style={{
                            position: 'absolute',
                            top: contextMenu.y,
                            left: contextMenu.x,
                            borderRadius: '10px',
                            border: '2px solid gray',
                            padding: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            zIndex: 1000,
                        }}
                        onMouseLeave={closeContextMenu}
                    >
                        {activeIndex !== null && activeIndex !== undefined && (
                            <>
                                <Tooltip label={t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.deselectPoint')}>
                                    <ActionIcon onClick={resetActiveIndex} disabled={history.length === 0}>
                                        <MapPinOff size={18} />
                                    </ActionIcon>
                                </Tooltip>
                                <Divider />
                            </>
                        )}

                        <Flex gap={10}>
                            <Tooltip label={t('scheme.redactor.buttons.undo')}>
                                <ActionIcon onClick={undo} disabled={history.length === 0}>
                                    <Undo size={18} />
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label={t('scheme.redactor.buttons.redo')}>
                                <ActionIcon onClick={redo} disabled={redoStack.length === 0}>
                                    <Redo size={18} />
                                </ActionIcon>
                            </Tooltip>
                        </Flex>

                        <Flex gap={10}>
                            <Tooltip label={t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.removeLastPoint')}>
                                <ActionIcon onClick={removeLastPoint} color="red">
                                    <CircleOff size={18} />
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label={t('scheme.redactor.panelOptions.setFigure.polilyne.polilyneEditor.clearPoints')}>
                                <ActionIcon onClick={clearPoints} color="red">
                                    <Trash size={18} />
                                </ActionIcon>
                            </Tooltip>
                        </Flex>
                    </Paper>
                )}
            </Modal>
        </>
    );
};

export default PolylineEditor;
