import {useEffect, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import classes from './BookingsModal.module.css'

const PrintTicketHtml = ({html, htmlMulti, onAfterPrint}: {
    html?: string,
    onAfterPrint: () => void,
    htmlMulti?: string[]
}) => {
    const ticketRef = useRef(null);


    const handlePrint = useReactToPrint({
        content: () => ticketRef.current,
        copyStyles: true,
        pageStyle: ``,
        onAfterPrint: () => {
            onAfterPrint && onAfterPrint();
        }
    });

    useEffect(() => {
        if (html || (htmlMulti && htmlMulti.length)) {
            console.log('print')
            handlePrint();
        }
    }, [html, JSON.stringify(htmlMulti)]);


    if (!html && (!htmlMulti || !htmlMulti.length)) {
        return <></>
    }
    if (htmlMulti && htmlMulti.length) {
        return (
            <div ref={ticketRef}
                 style={{
                     width: "100%",
                     height: "100%",
                 }}
                 className={classes.ticketPrint}>
                {htmlMulti.map(item => {
                    return (
                        <iframe srcDoc={item}
                                width={'100%'}
                                height={'100%'}
                        >
                        </iframe>
                    )
                })}
            </div>
        )
    }
    return (
        <iframe srcDoc={html}
                width={'100%'}
                height={'100%'}
                ref={ticketRef}
                className={classes.ticketPrint}
        >
        </iframe>
    )
}

export default PrintTicketHtml