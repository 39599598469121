import React, {useState} from "react";
import {ActionIcon, Flex, Text} from "@mantine/core";
import {PencilIcon} from "lucide-react";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useGetContentQuery} from "../../../api/web-content";

const page_size = 10;
const WebContentPage = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const {data = {results: [], count: 0}, isFetching} = useGetContentQuery({
        page,
        page_size: 10,
        language: i18n.language,
    });

    return (
        <>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                layoutType={'fixed'}
                columns={[
                    {
                        label: t("content_category.form.name"), name: "first_name", render: (_, record) => {
                            return (
                                <Flex align={"center"}>
                                    <Text style={{
                                        whiteSpace: "nowrap",
                                        width: "calc(100% - 50px)",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>{record.value}</Text>
                                </Flex>
                            );
                        }
                    },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                         width: "100px",
                        render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"}
                                            onClick={() => {
                                                navigate("/web/content/" + record.id);
                                            }}
                                >
                                    <PencilIcon size={16}/>
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => navigate("/web/content/create")}
                createTitle={'Создать контент'}
            />
        </>
    )
}

export default WebContentPage