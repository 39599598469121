import { Select, Flex, TextInput, Button, Title, Divider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { isErrorWithMessage, showErrorMessages } from "../../../../app/api";
import { notification } from "../../../../utils/notification";
import { useSchemeFormContext } from "../SchemeList";
import { useApplySchemeMutation, useCreateSchemeMutation, useReadSchemeQuery, useUpdateSchemeMutation } from "../../../../api/scheme";
import { IHall } from "../../../../api/hall";
import { toNumber } from "lodash";
import { useEffect } from "react";

interface SchemeListModalFormProps {
    recordId: number | null;
    halls?: IHall[];
    canEditScheme: boolean;
    onClose: () => void;
}

const SchemeListModalForm = ({ recordId, halls, canEditScheme, onClose }: SchemeListModalFormProps) => {
    const { t } = useTranslation();
    const form = useSchemeFormContext();
    const [createScheme] = useCreateSchemeMutation();
    const [updateScheme] = useUpdateSchemeMutation();
    const [applyScheme] = useApplySchemeMutation();

    const { data: schemeRecord } = useReadSchemeQuery(
        { id: Number(recordId) },
        { skip: !recordId }
    );

    useEffect(() => {
        if (recordId && schemeRecord) {
            form.setFieldValue("name", schemeRecord.name);
            form.setFieldValue("hallId", String(schemeRecord.hall));
        } else {
            form.reset();
        }
    }, [recordId]);

    const onSubmit = async (values: any) => {
        try {
            const payload = {
                name: values.name,
                hall: toNumber(values.hallId),
                data: []
            };

            let response;

            if (recordId) {
                response = await updateScheme({ id: recordId, data: payload }).unwrap();

            } else {
                response = await createScheme(payload).unwrap();
            }

            notification({
                type: "success",
                title: recordId ? t("scheme.notifications.updated") : t("scheme.notifications.created"),
            }); onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.error(e);
        }
    };

    const handleApplyScheme = async () => {
        try {
            await applyScheme({ id: schemeRecord!.id }).unwrap();

            notification({
                type: "success",
                title: t("scheme.notifications.movedToStateReady"),
            });
        } catch (error) {
            console.error("Failed to apply scheme: ", error);
            notification({
                type: "error",
                title: t("scheme.notifications.errors.movedToStateReadyFail"),
            });
        }
    };

    return (
        <form
            id="SchemeForm"
            onSubmit={form.onSubmit(onSubmit)}
        >
            <Title mt={15} order={4}>{t('scheme.editSchemeInfo')}</Title>
            <Divider mt={10} mb={15} />
            <Flex
                gap={20}
            >
                <TextInput
                    w="100%"
                    label={t("scheme.form.name")}
                    placeholder={t("scheme.form.name")}
                    {...form.getInputProps("name")}
                />
                <Select
                    w="100%"
                    label={t("events.form.hall")}
                    placeholder={t("events.form.hall")}
                    data={halls!.map((hall) => ({ value: String(hall.id), label: hall.name }))}
                    comboboxProps={{ zIndex: 500 }}
                    {...form.getInputProps("hallId")}
                />
            </Flex>

            {
                schemeRecord?.state === "draft" && canEditScheme &&
                <>
                    <Title mt={25} order={4}>{t('scheme.actionsOnSchema')}</Title>
                    <Divider mt={10} mb={15} />
                    <Button
                        color="orange"
                        onClick={handleApplyScheme}
                    >
                        {t('scheme.btnMoveToStateReady')}
                    </Button>
                </>
            }
        </form>
    );
};

export default SchemeListModalForm;