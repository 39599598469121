import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ActionIcon, Box, Button, Flex, Select} from "@mantine/core";
import {LanguageChanger} from "../../content/Content";
import {useTranslation} from "react-i18next";
import {
    useCreateContentMutation,
    useLazyReadContentQuery,
    useRemoveContentMutation,
    useUpdateContentMutation
} from "../../../api/web-content";
import QuillTextEditor from "../../../components/QuillTextEditor";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";
import {isErrorWithMessage, showErrorMessages} from "../../../app/api";
import {ArrowLeft} from "lucide-react";
import {useGetContentCategoriesQuery} from "../../../api/web-content-categories";
import {notification} from "../../../utils/notification";


const WebContentForm = () => {
    //LNG
    const {i18n, t} = useTranslation();
    const currentLng = i18n.language === "ru" ? "ru" : "ro";

    //HOOKS
    const params = useParams();
    const [ConfirmModal, confirmDelete] = useConfirmationModal({});
    const navigate = useNavigate();

    //STATE
    const [value, setValue] = useState<string>()
    const [category, setCategory] = useState<string | null>();
    const [language, setLanguage] = useState<'ro' | 'ru' | 'en'>(currentLng);


    //API
    const [readContent] = useLazyReadContentQuery()
    const [create] = useCreateContentMutation();
    const [update] = useUpdateContentMutation();
    const [remove] = useRemoveContentMutation();
    const {data: categoriesData = {results: [], count: 0}, isFetching} = useGetContentCategoriesQuery({});

    useEffect(() => {
        if (params.id && language) {
            readContent({id: Number(params.id), language: language}).then(res => {
                if (res.data) {
                    setValue(res.data.value)
                    setCategory(res.data.category)
                }
            });
        }
    }, [params.id, language]);

    async function saveContent() {

        if (!value || !category) return;

        if (params.id) {
            //update
            try {
                await update({
                    id: Number(params.id),
                    language,
                    value,
                    category,
                }).unwrap();
                navigate('/web/content/query')
                notification({
                    type: "success",
                    title: "Контент обновлен успешно"
                });
            } catch (e) {
                if (isErrorWithMessage(e)) {
                    showErrorMessages(e.data);
                }
            }

            return
        }

        try {
            await create({
                id: Number(params.id),
                language,
                value,
                category,
            }).unwrap();
            navigate('/web/content/query')
            notification({
                type: "success",
                title: "Контент создан успешно"
            });
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }


    }

    async function removeContent() {
        const isConfirmed = await confirmDelete();
        if (params.id && isConfirmed) {
            try {
                await remove({id: Number(params.id)}).unwrap();
                navigate('/web/content/query')
                notification({
                    type: "success",
                    title: "Контент удален успешно"
                });
            } catch (e) {
                if (isErrorWithMessage(e)) {
                    showErrorMessages(e.data);
                }
            }
        }
    }

    return (
        <>
            <Flex justify={'space-between'}>
                <Box>
                    <ActionIcon onClick={() => navigate(-1)}>
                        <ArrowLeft/>
                    </ActionIcon>
                </Box>
                <Flex align={'center'}>
                    <Select
                        required
                        placeholder={t('common.select')}
                        data={categoriesData.results.map((b) => {
                            return {value: String(b.code), label: b.name || String(b.name)};
                        })}
                        comboboxProps={{
                            zIndex: 500
                        }}
                        mr={10}
                        value={category}
                        onChange={v => setCategory(v)}
                    />
                    <LanguageChanger language={language} setLanguage={setLanguage}/>
                </Flex>
            </Flex>
            <Box mt={20} h={300}>
                <QuillTextEditor value={value} onChange={setValue} styles={{
                    height: '100%',
                }}/>
            </Box>
            <Flex justify={'flex-end'} mt={50}>
                {params.id && (
                    <Button onClick={removeContent} variant={"outline"} color={'red'} mr={10}>
                        Удалить
                    </Button>
                )}
                <Button onClick={saveContent}>
                    Сохранить
                </Button>
            </Flex>
            <ConfirmModal/>
        </>
    )
}

export default WebContentForm;