import React from "react";
import { Modal, List, ListItem, Title, Text, Divider, Space } from "@mantine/core"; // Added Text import

interface HelpModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            title="Schema Editor Help"
            centered
            size="xl"
        >
            <Text size="md" mb="md">
                This is a schema editor, and only administrators have the permission to make changes to the schema.
            </Text>

            <Divider />
            <Space h={"md"} />
            <Title order={3}>Schema modes</Title>
            <Space h={"md"} />

            <List spacing="md">
                <ListItem>
                    <Title order={4}>View Mode</Title>
                    <Text size="md">
                        In this mode, you can view the schema but cannot make changes. 
                    </Text>
                </ListItem>

                <ListItem>
                    <Title order={4}>Edit Mode</Title>
                    <Text size="md">
                        In this mode, only administrators can switch to Edit Mode.
                    </Text>
                </ListItem>
            </List>
        </Modal>
    );
};

export default HelpModal;
