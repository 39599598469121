import React from "react";
import { Select, Loader } from "@mantine/core";
import { IHallPart } from "../../../../../api/hall";

interface HallPartsSelectorProps {
    selectedHallPart?: string;
    hallParts: IHallPart[];
    hallPartsLoading: boolean;
    readOnly?: boolean;
    onChange: (value: string) => void;
    t: (key: string) => string;
}

const HallPartsSelector: React.FC<HallPartsSelectorProps> = ({
    selectedHallPart,
    hallParts,
    hallPartsLoading,
    readOnly,
    onChange,
    t
}) => {
    if (hallPartsLoading) {
        return <Loader size={38} />;
    }
    if (!hallParts || hallParts.length === 0) {
        return <div>{t('scheme.redactor.panelOptions.setSector.selectHallPart.noData')}</div>;
    }

    const options = hallParts.map((part: IHallPart) => {
        return {
            value: String(part.id),
            label: part.name,
        };
    });

    const selectedValue = selectedHallPart
        ? options.find(option => option.label === selectedHallPart)?.value
        : '';
    const handleChange = (value: string | null) => {
        if (value) {
            const selectedPart = hallParts.find((part) => String(part.id) === value);
            if (selectedPart) {
                onChange(selectedPart.name);
            }
        }
    };

    return (
        <Select
            w={"100%"}
            label={t('scheme.redactor.panelOptions.setSector.selectHallPart.label')}
            placeholder={t('scheme.redactor.panelOptions.setSector.selectHallPart.placeholder')}
            data={options}
            onChange={handleChange}
            value={selectedValue}
            readOnly={readOnly}
        />
    );
};

export default HallPartsSelector;
