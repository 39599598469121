import {
    Button, Card,
    Flex,
    LoadingOverlay,
    Pagination,
    ScrollArea,
    Table,
    TextInput, useMantineColorScheme
} from "@mantine/core";
import React from "react";
import { usePagination } from "@mantine/hooks";
import classes from "./DefaultTable.module.css";
import Th from "./Th";
import { useTranslation } from "react-i18next";

interface IColumn {
    name: string;
    label: string;
    type?: string;
    width?: string;
    render?: (string: any, obj: any) => any;
    sortable?: boolean;
}

interface IProps {
    isFetching: boolean;
    columns: IColumn[];
    data: { results: any[]; totalPages: number };
    onRowClick?: (item: any) => void;
    onChangePage: (page: number) => void;
    page: number;
    sort?: { column: string; direction: string };
    setSort?: (v: string) => void;
    onSearch?: (v: string) => void;
    enableSearch?: boolean;
    searchPlaceholder?: string;
    creatable?: boolean;
    onClickCreate?: () => void;
    createTitle?: string;
    layoutType?: 'fixed' | 'auto'
}

const DefaultTable = (props: IProps) => {
    const {
        isFetching,
        columns,
        data,
        onRowClick = () => {
        },
        page,
        onChangePage,
        onSearch,
        sort,
        setSort,
        enableSearch,
        searchPlaceholder,
        creatable,
        onClickCreate,
        createTitle,
        layoutType = 'auto'
    } = props;
    const { t } = useTranslation();
    const colorScheme = useMantineColorScheme();
    const pagination = usePagination({
        total: data.totalPages,
        initialPage: 1,
        page: page,
        onChange: onChangePage
    });
    return (
        <>
            <Flex align={"center"} mb="md">
                {enableSearch && onSearch && (
                    <TextInput
                        style={{ width: "100%" }}
                        placeholder={searchPlaceholder || t("common.search")}
                        onChange={(e) => onSearch(e.target.value)}
                    />
                )}
                {creatable && onClickCreate && (
                    <Button ml={10} onClick={onClickCreate}>
                        {createTitle ? createTitle : t("common.create")}
                    </Button>
                )}
            </Flex>
            <Card p={0}>
                <LoadingOverlay visible={isFetching} />
                <ScrollArea>
                    <Table highlightOnHover verticalSpacing={"sm"} layout={layoutType}>
                        <Table.Thead bg={colorScheme.colorScheme === "dark" ? "dark.4" : "gray.0"}>
                            <Table.Tr>
                                {columns.map((column, i) => (
                                    <Th
                                        key={i}
                                        sortable={Boolean(column.sortable)}
                                        width={column.width || "auto"}
                                        onSort={() => setSort && setSort(column.name)}
                                        sorted={Boolean(
                                            sort?.column === column.name && sort.direction
                                        )}
                                        reversed={Boolean(
                                            sort?.column === column.name &&
                                            sort.direction === "descend"
                                        )}
                                    >
                                        {(column.label).toUpperCase()}
                                    </Th>
                                ))}
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {(data || { results: [] }).results.map((item: any, i: number) => {
                                return (
                                    <Table.Tr key={i} onClick={() => onRowClick(item)}>
                                        {columns.map((column: IColumn, i) => {
                                            return (
                                                <Table.Td key={i}>
                                                    {column.render
                                                        ? column.render(item[column.name], item)
                                                        : item[column.name]}
                                                </Table.Td>
                                            );
                                        })}
                                    </Table.Tr>
                                );
                            })}
                        </Table.Tbody>
                    </Table>
                </ScrollArea>
                <div className={classes.paginationContainer}>
                    <Pagination
                        value={page}
                        total={(data || { totalPages: 0 }).totalPages}
                        onChange={(value) => {
                            pagination.setPage(value);
                        }}
                    />
                </div>
            </Card>
        </>
    );
};
export default DefaultTable;

DefaultTable.defaultProps = {
    setSort: () => {
    },
    sort: { column: null },
};
