import PageContainer from "../../../components/PageContainer";
import {useGetEventsQuery} from "../../../api/event";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import {useState} from "react";
import dayjs from "dayjs";
import {Button, Text} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SeatValidate = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const page_size = 10;
    const {t} = useTranslation();

    const {data: events = {results: [], count: 0}, isFetching} = useGetEventsQuery({
        ordering: "start",
        page,
        page_size,
        start_gte: dayjs(new Date()).format("YYYY-MM-DDT00:00:00")
    })

    return (
        <PageContainer>
            <DefaultTable
                data={{
                    ...events,
                    totalPages: Math.ceil(events.count / page_size)
                }}
                columns={[
                    {
                        label: t('statistics.event'), name: "name", render: (value, record) => {
                            return (
                                <>
                                    <Text size={'xs'} style={{
                                        // lineClamp: 2,
                                        overflow: "hidden",
                                        display: '-webkit-box',
                                        '-webkit-box-orient': 'vertical',
                                        '-webkit-line-clamp': "2",
                                        'line-clamp': 2
                                    }}>{value}</Text>
                                    <Text size={'xs'} mt={5}>{dayjs(record.start).format("DD MMMM YYYY HH:mm")}</Text>
                                </>
                            )
                        }
                    },
                    {
                        label: "", name: "_actions", render: (value, row) => {
                            const startDateTime = Number(new Date(row.start)); // Example startDateTime
                            const now = Number(new Date());
                            const differenceInMs = startDateTime - now;
                            const hoursLeft = Math.floor(differenceInMs / (1000 * 60 * 60));
                            return (
                                <Button size={'xs'}
                                        disabled={hoursLeft > 12}
                                        onClick={() => {
                                            navigate(`/management/ticket-validation/${row.id}`)
                                        }}
                                >
                                    {t("seatValidate.startScanningQRCode")}
                                </Button>
                            )
                        }
                    }
                ]}
                isFetching={isFetching}
                page={page}
                onChangePage={setPage}
            />
        </PageContainer>
    )
}
export default SeatValidate;