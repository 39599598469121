import { Modal, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { IInvoice, useLazyGetInvoicesQuery } from "../../../api/invoices";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

type TProps = {
    isOpen: boolean,
    onClose: () => any,
    booking: number | null
}

const InvoiceHistory = (props: TProps) => {
    const { t } = useTranslation();
    const { isOpen, onClose, booking } = props;
    const [getInvoices] = useLazyGetInvoicesQuery();
    const [data, setData] = useState<IInvoice[]>([])
    useEffect(() => {
        if (booking && isOpen) {
            //get data
            fetchInvoices(booking);
        }
    }, [isOpen, booking])

    async function fetchInvoices(booking: number) {
        const invoices = await getInvoices({ booking }).unwrap();
        setData(invoices.results);
        console.log(invoices)
    }

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            centered={true}
            size={'lg'}
            title={t("orders.historyTitle") + " " + booking}
        >
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Td>{t("orders.columns.transaction")}</Table.Td>
                        <Table.Td>{t("orders.columns.order_date")}</Table.Td>
                        <Table.Td>{t("orders.columns.order_author")}</Table.Td>
                        <Table.Td>{t("orders.columns.status")}</Table.Td>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {data.length === 0 ? (
                        <Table.Tr>
                            <Table.Td colSpan={4} style={{ textAlign: "center" }}>
                                {t("common.data_not_found")}
                            </Table.Td>
                        </Table.Tr>
                    ) : (
                        data.map(invoice => (
                            <Table.Tr key={invoice.id}>
                                <Table.Td>{invoice.id}</Table.Td>
                                <Table.Td>{dayjs(invoice.timestamp).format("DD-MM-YYYY HH:mm")}</Table.Td>
                                <Table.Td>
                                    {invoice.customer?.first_name} {invoice.customer?.last_name} {invoice.customer.email}
                                </Table.Td>
                                <Table.Td>{invoice.status}</Table.Td>
                            </Table.Tr>
                        ))
                    )}
                </Table.Tbody>
            </Table>
        </Modal>
    )
}
export default InvoiceHistory;