import { ColorInput, Flex, TextInput } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';
import { RectStyles } from '../../../interfaces/SchemeEdit.interface';

interface RectStylesPanelProps {
    rectStyles: RectStyles;
    setRectStyles: Dispatch<SetStateAction<RectStyles>>;
    t: (key: string) => string;
}

const RectStylesPanel = ({ rectStyles, setRectStyles, t }: RectStylesPanelProps) => {
    return (
        <Flex wrap={"wrap"} gap={10}>
            <Flex w={"100%"} gap={10}>
                <TextInput
                    label={t('scheme.redactor.panelOptions.setFigure.rect.x')}
                    value={rectStyles.x}
                    onChange={(e) => setRectStyles({ ...rectStyles, x: e.target.value })}
                />
                <TextInput
                    label={t('scheme.redactor.panelOptions.setFigure.rect.y')}
                    value={rectStyles.y}
                    onChange={(e) => setRectStyles({ ...rectStyles, y: e.target.value })}
                />
            </Flex>

            <Flex w={"100%"} gap={10}>
                <TextInput
                    w={"48%"}
                    label={t('scheme.redactor.panelOptions.setFigure.rect.width')}
                    value={rectStyles.width}
                    onChange={(e) => setRectStyles({ ...rectStyles, width: e.target.value })}
                />
                <TextInput
                    w={"48%"}
                    label={t('scheme.redactor.panelOptions.setFigure.rect.height')}
                    value={rectStyles.height}
                    onChange={(e) => setRectStyles({ ...rectStyles, height: e.target.value })}
                />
            </Flex>

            <ColorInput
                w={"100%"}
                label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeColor')}
                value={rectStyles.fill}
                onChange={(value) => setRectStyles({ ...rectStyles, fill: value })}
            />

            <TextInput
                w={"100%"}
                label={t('scheme.redactor.panelOptions.setFigure.rect.rx')}
                value={rectStyles.rx}
                onChange={(e) => setRectStyles({ ...rectStyles, rx: e.target.value })}
            />
            <TextInput
                w={"100%"}
                label={t('scheme.redactor.panelOptions.setFigure.rect.ry')}
                value={rectStyles.ry}
                onChange={(e) => setRectStyles({ ...rectStyles, ry: e.target.value })}
            />
        </Flex>
    );
};

export default RectStylesPanel;
