import { Flex, Tooltip, ActionIcon, Divider } from "@mantine/core";
import { MousePointer2Icon, CircleDot, CircleOff, Type, Shapes } from "lucide-react";

type ModeTogglePanelProps = {
    setSelectedKey: React.Dispatch<React.SetStateAction<string>>,
    selectItemMode: boolean;
    setSelectItemMode: (value: boolean) => void;
    dotInsertMode: boolean;
    setDotInsertMode: (value: boolean) => void;
    deleteCircle: boolean;
    setDeleteCircle: (value: boolean) => void;
    textInsertMode: boolean;
    setTextInsertMode: (value: boolean) => void;
    shapeInsertMode: boolean;
    setShapeInsertMode: (value: boolean) => void;
    setBlockSelection: (value: boolean) => void;
    setSelectedKeyPolilyne: React.Dispatch<React.SetStateAction<string>>;
    setSelectedKeyRect: React.Dispatch<React.SetStateAction<string>>;
    setSelectedKeyCustomCircle: React.Dispatch<React.SetStateAction<string>>;
    t: (key: string) => string;
    deSelectAll: () => void;
};

const ModeTogglePanel: React.FC<ModeTogglePanelProps> = ({
    setSelectedKey,
    selectItemMode,
    setSelectItemMode,
    dotInsertMode,
    setDotInsertMode,
    deleteCircle,
    setDeleteCircle,
    textInsertMode,
    setTextInsertMode,
    shapeInsertMode,
    setShapeInsertMode,
    setBlockSelection,
    setSelectedKeyPolilyne,
    setSelectedKeyRect,
    setSelectedKeyCustomCircle,
    t,
    deSelectAll
}) => {
    const mx = 10;

    return (
        <Flex
            gap={10}
            wrap="wrap"
            direction={"column"}
        >
            <Divider />

            <Tooltip label={t('scheme.redactor.modeTogglePanel.selectItemMode')} position="right">
                <ActionIcon
                    mx={mx}
                    variant={selectItemMode ? "filled" : "light"}
                    onClick={() => {
                        setSelectedKey('');
                        setSelectedKeyPolilyne('');
                        setSelectedKeyRect('');
                        setSelectedKeyCustomCircle('');
                        setBlockSelection(false);
                        setSelectItemMode(!selectItemMode);
                        setDotInsertMode(false);
                        setDeleteCircle(false);
                        setTextInsertMode(false);
                        setShapeInsertMode(false);
                        deSelectAll();
                    }}
                >
                    <MousePointer2Icon size={20} />
                </ActionIcon>
            </Tooltip>

            <Divider />

            <Tooltip label={t('scheme.redactor.modeTogglePanel.dotInsertMode')} position="right">
                <ActionIcon
                    mx={mx}
                    variant={dotInsertMode ? "filled" : "light"}
                    onClick={() => {
                        setSelectedKeyPolilyne('');
                        setSelectedKeyRect('');
                        setSelectedKeyCustomCircle('');
                        setBlockSelection(true);
                        setDotInsertMode(!dotInsertMode);
                        setSelectItemMode(false);
                        setDeleteCircle(false);
                        setTextInsertMode(false);
                        setShapeInsertMode(false);
                        deSelectAll();
                    }}
                >
                    <CircleDot size={20} />
                </ActionIcon>
            </Tooltip>

            <Tooltip label={t('scheme.redactor.modeTogglePanel.deleteCircle')} position="right">
                <ActionIcon
                    mx={mx}
                    variant={deleteCircle ? "filled" : "light"}
                    onClick={() => {
                        setSelectedKey('');
                        setSelectedKeyPolilyne('');
                        setSelectedKeyRect('');
                        setSelectedKeyCustomCircle('');
                        setBlockSelection(true);
                        setDeleteCircle(!deleteCircle);
                        setDotInsertMode(false);
                        setSelectItemMode(false);
                        setTextInsertMode(false);
                        setShapeInsertMode(false);
                        deSelectAll();
                    }}
                >
                    <CircleOff size={20} />
                </ActionIcon>
            </Tooltip>

            <Divider />

            <Tooltip label={t('scheme.redactor.modeTogglePanel.textInsertMode')} position="right">
                <ActionIcon
                    mx={mx}
                    variant={textInsertMode ? "filled" : "light"}
                    onClick={() => {
                        setSelectedKey('');
                        setSelectedKeyPolilyne('');
                        setSelectedKeyRect('');
                        setSelectedKeyCustomCircle('');
                        setBlockSelection(true);
                        setTextInsertMode(!textInsertMode);
                        setDeleteCircle(false);
                        setDotInsertMode(false);
                        setSelectItemMode(false);
                        setShapeInsertMode(false);
                        deSelectAll();
                    }}
                >
                    <Type size={20} />
                </ActionIcon>
            </Tooltip>

            <Tooltip label={t('scheme.redactor.modeTogglePanel.shapeInsertMode')} position="right">
                <ActionIcon
                    mx={mx}
                    variant={shapeInsertMode ? "filled" : "light"}
                    onClick={() => {
                        setSelectedKey('');
                        setSelectedKeyPolilyne('');
                        setSelectedKeyRect('');
                        setSelectedKeyCustomCircle('');
                        setBlockSelection(true);
                        setShapeInsertMode(!shapeInsertMode)
                        setTextInsertMode(false);
                        setDeleteCircle(false);
                        setDotInsertMode(false);
                        setSelectItemMode(false);
                        deSelectAll();
                    }}
                >
                    <Shapes size={20} />
                </ActionIcon>
            </Tooltip>
        </Flex>
    );
};

export default ModeTogglePanel;