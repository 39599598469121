import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ActionIcon, Box, Divider, Flex, Loader, Menu, rem, Table, Text
} from "@mantine/core";
import {ChevronDown, History, MoreVertical, Printer, Undo2} from "lucide-react";
import dayjs from "dayjs";

//hooks
import { useAppSelector } from "../../../app/hooks";
import { useConfirmationModal } from "../../../hooks/useConfirmationModal";

import PrintTicketHtml from "../../sales/properties/TicketsOperations/PrintTicketHtml";
import InvoiceHistory from "./InvoiceHistory";

import { IBooking } from "../../../api/booking";
import { isErrorWithMessage, showErrorMessages } from "../../../app/api";
//API
import { useInvoiceRefundMutation } from "../../../api/invoices";
import {useCancelReserveMutation, useLazyGetTicketHtmlQuery, useLazyGetTicketsQuery} from "../../../api/event";
import { useRefundTicketsMutation } from "../../../api/sales";
import CollapsedContent from "./CollapsedContent";
import {showNotification} from "@mantine/notifications";

const ExpandRow = ({ booking, type, onClickChangeType }: {
    booking: IBooking,
    type: 'cash-box_orders' | 'internet_orders',
    onClickChangeType: (r: IBooking) => void
}) => {
    //hooks
    const { t } = useTranslation();

    //state
    const [ticket, setTicket] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ticketsToPrint, setTicketsToPrint] = useState<string[] | undefined>([]);
    const [ConfirmModal, confirmDelete] = useConfirmationModal({});
    const [inCollapse, setInCollapse] = useState<{ isOpen: boolean, bookingId?: number }>({ isOpen: false });
    const [isOpenHistory, setIsOpenHistory] = useState<{ isOpen: boolean, booking: number | null }>({
        isOpen: false,
        booking: null
    });


    //API
    const [refundInvoice] = useInvoiceRefundMutation();
    const [refundTickets] = useRefundTicketsMutation();
    const [cancelReserve] = useCancelReserveMutation();
    const [getTickets] = useLazyGetTicketsQuery();
    const [getTicketHtml] = useLazyGetTicketHtmlQuery();
    //redux
    const activeBoxOffice = useAppSelector(state => state.sales.activeBoxOffice);
    const cashierToken = useAppSelector(state => state.sales.cashierToken);
    const isCashier = Boolean(activeBoxOffice && cashierToken);

    async function returnBooking() {
        try {
            const isConfirmed = await confirmDelete();

            if (!isConfirmed) return;
            if (!booking.id) return;
            if (type === 'cash-box_orders') {
                await cancelReserve({ booking: booking.id }).unwrap();
                return
            }
            await refundInvoice({
                event: booking.event.id,
                items: booking.tickets.map(ticket => {
                    return { ticket: ticket.id }
                })
            }).unwrap();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    async function refundTicket(ticket: number) {
        try {
            const isConfirmed = await confirmDelete(`Подтверждение возврата билета №${ticket}`);

            if (!isConfirmed) return;

            if (type === 'cash-box_orders' && booking.id) {
                await refundTickets({
                    boxOfficeId: Number(activeBoxOffice),
                    event: booking.event.id,
                    items: [{ ticket }],
                    token: cashierToken
                }).unwrap();
            } else {
                await refundInvoice({
                    event: booking.event.id,
                    items: [{ ticket }]
                }).unwrap();
            }

            const tickets = await getTickets({
                booking: booking.id
            }).unwrap();

            setInCollapse(prev => {
                return {
                    ...prev,
                    tickets: tickets.results
                }
            })
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    async function onClickPrint(ticket: any) {
        const {html} = await getTicketHtml({id: ticket.id}).unwrap();
        setTicket(html)
    }

    async function onClickPrintAll() {
        setIsLoading(true)

        await new Promise((resolve) => setTimeout(async () => {
            try {
                const ticketsToPrint = await getTickets({booking: booking.id, page_size: 999}).unwrap()
                if (ticketsToPrint.results) {
                    const tickets = [];
                    for await (const ticket of ticketsToPrint.results) {
                        let {html} = await getTicketHtml({id: ticket.id}).unwrap();
                        if (!html) {
                            showNotification({
                                color: "orange",
                                title: "Билеты к печати не готовы, подождите",
                                message: ""
                            });
                            resolve(false);
                            return
                        }
                        tickets.push(html);
                    }
                    setTicketsToPrint(tickets.reverse())
                    resolve(true)
                }
            } catch (e) {
                setIsLoading(false)
                console.log(e)
                if (isErrorWithMessage(e)) {
                    showErrorMessages(e.data);
                }
            }
        }, 1000));

        setIsLoading(false)

    }

    async function showHistory(bookingId?: number) {
        if (!bookingId) return;

        setIsOpenHistory({
            isOpen: true,
            booking: bookingId
        });
    }

    async function showTickets(bookingId?: number) {
        if (!bookingId) return;

        setInCollapse(state => {
            return {
                isOpen: !state.isOpen,
                bookingId,
            }
        })
    }

    return (
        <>
            <Table.Tr>
                <Table.Td>
                    {booking.id}
                </Table.Td>
                <Table.Td>
                    <Text size={'sm'}>{dayjs(booking.created).format("DD MMMM YYYY HH:mm")}</Text>
                    {booking.description && (
                        <>
                            <Divider my={2}/>
                            <Text size={'sm'}>{booking.description}</Text>
                        </>
                    )}
                </Table.Td>
                <Table.Td>
                    {booking.event.name}, <br />{dayjs(booking.event.start).format("DD MMMM YYYY HH:mm")}
                </Table.Td>
                <Table.Td>
                    <span>{booking.author?.first_name} {booking.author?.last_name}</span>{(booking.author?.first_name || booking.author?.last_name) &&
                        <br />}
                    <span>{booking.author?.email}</span>
                </Table.Td>
                <Table.Td align={"right"}>
                    <Flex justify={"space-between"} align={"center"}>
                        <Box>
                            <Text size={'sm'}
                                c={booking.status === 'sold' ? 'teal' : 'orange'}>{t(`orders.${booking.status}`)}</Text>
                        </Box>
                        <Flex pl={25} pt={5}>
                            <Menu width={200} shadow="md">
                                <Menu.Target>
                                    <ActionIcon size={"sm"} mr={10} variant={"transparent"} color={"gray"}>
                                        {isLoading ? <Loader size={20} /> : <MoreVertical size={20} />}
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item
                                        onClick={returnBooking}
                                        leftSection={<Undo2 style={{ width: rem(14), height: rem(14) }} />}
                                    >
                                        {t('orders.menu_dropdown_actions.return')}
                                    </Menu.Item>
                                    <Menu.Item
                                        leftSection={<History style={{ width: rem(14), height: rem(14) }} />}
                                        onClick={() => showHistory(booking.id)}
                                    >
                                        {t('orders.menu_dropdown_actions.history')}
                                    </Menu.Item>
                                    <Menu.Item
                                        leftSection={<History style={{ width: rem(14), height: rem(14) }} />}
                                        onClick={() => onClickChangeType(booking)}
                                    >
                                        {t('orders.menu_dropdown_actions.change_sale_type')}
                                    </Menu.Item>
                                    <Menu.Item
                                        leftSection={<Printer style={{ width: rem(14), height: rem(14) }} />}
                                        onClick={() => onClickPrintAll()}

                                    >
                                       Печать всех билетов
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                            <ActionIcon size={"sm"} variant={"transparent"} onClick={() => showTickets(booking.id)}>
                                <ChevronDown size={20} />
                            </ActionIcon>
                        </Flex>
                    </Flex>
                </Table.Td>
            </Table.Tr>
            <Table.Tr style={{ border: "none" }}>
                <Table.Td colSpan={6} py={0} px={20} h={inCollapse.isOpen ? "auto" : 0} style={{ border: "none" }}>
                    <CollapsedContent
                        inCollapse={inCollapse}
                        booking={booking}
                        isCashier={isCashier}
                        onClickPrint={onClickPrint}
                        refundTicket={refundTicket}
                    />
                </Table.Td>
            </Table.Tr>
            <ConfirmModal />
            <InvoiceHistory isOpen={isOpenHistory.isOpen} booking={isOpenHistory.booking}
                onClose={() => setIsOpenHistory({
                    isOpen: false,
                    booking: null
                })} />
            <PrintTicketHtml
                html={ticket}
                htmlMulti={ticketsToPrint}
                onAfterPrint={() => {
                    setTicket("")
                    setTicketsToPrint([])
                }}
            />
        </>
    )
}
export default ExpandRow