import React from "react";
import {TextInput} from "@mantine/core";

import {notification} from "../../../utils/notification";


//API
import {useTranslation} from "react-i18next";
import {isErrorWithMessage, showErrorMessages} from "../../../app/api";
import {useWebCategoryFormContext} from "./ContentCategory";
import {
    IContentCategoryRead,
    useCreateContentCategoryMutation,
    useUpdateContentCategoryMutation
} from "../../../api/web-content-categories";


const HallForm = (props: { recordId?: string, onClose: () => void }) => {
    const {t} = useTranslation();
    const form = useWebCategoryFormContext();
    const [create] = useCreateContentCategoryMutation();
    const [update] = useUpdateContentCategoryMutation();

    async function onSubmit(values: IContentCategoryRead) {
        try {
            props.recordId ?
                await update({...values, code: props.recordId}).unwrap() :
                await create(values).unwrap();

            notification({
                type: "success",
                title: props.recordId ? t("content_category.notifications.updated") : t("content_category.notifications.created")
            });
            props.onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <form id={"webCategoryForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values);
        })}>
            <TextInput
                label={t("content_category.form.code")}
                required
                {...form.getInputProps("code")}
            />
            <TextInput
                label={t("content_category.form.name")}
                required
                {...form.getInputProps("name")}
            />
        </form>
    );
};
export default HallForm;
