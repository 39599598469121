import React, { useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import Seat from "./Seat";
import { drawSelectionArea, getSVGPoint, intersectDim, isStartDrawing } from "./utils";
import classes from "./Schema.module.css";
import { useTranslation } from "react-i18next";
import { TText } from "../../pages/sales/sales.slice";

let offset = {
    x: 0,
    y: 0
};
let rectStartX = 0;
let rectStartY = 0;
let startX = 0;
let startY = 0;

interface IProps {
    data: {
        data: {
            [key: string]: {
                id: number,
                zone: number | null,
                selected?: boolean,
                ticket?: number,
                status?: "free" | "reserved" | "sold" | "disabled"
                rect: {
                    x: string,
                    y: string
                }
            }
        },
    },
    text?: TText,
    zones: any[],
    viewBox?: string,
    type: 'price' | 'sales',
    preferredLanguage?: 'ro' | 'en' | 'ru',
    showOnly?: "reserved" | "free" | "sold",
    widgetMode?: boolean,
    blockSelection?: boolean,
    showHints?: boolean,
    styles?: React.CSSProperties;
    onScaleChange?: (scale: number) => void,
    onSeatClick?: (
        id: number,
        key: string,
        zone?: number | null,
        status?: "free" | "reserved" | "sold" | "disabled",
        ticket?: number,
        isShortCut?: boolean
    ) => void,
    onTextClick?: (e: React.MouseEvent<SVGTextElement>) => void,
    onPolylineClick?: (e: React.MouseEvent<SVGTextElement>) => void,
    onRectClick?: (e: React.MouseEvent<SVGTextElement>) => void,
    onCircleClick?: (e: React.MouseEvent<SVGTextElement>) => void,
    onSelectionArea?: (tmpSelection: any[], collectedSeats: any[]) => void,
    onClick?: (e: React.MouseEvent<SVGSVGElement>) => void,
    onRightClick?: (e: React.MouseEvent) => void;
}

const Schema = (props: IProps) => {
    const {
        data, zones, blockSelection, styles,
        onScaleChange, onSeatClick, onTextClick, onPolylineClick, onRectClick, onCircleClick, onSelectionArea, onClick, onRightClick,
        showHints, showOnly, viewBox = "0 -7 1030 750",
        widgetMode, type, preferredLanguage, text
    } = props;
    const [scale, setScale] = useState(1);
    const { i18n: { language } } = useTranslation();
    const containerRef = useRef<any>(null);
    const tmpSelection = useRef<string[]>([]);
    const viewPortRef = useRef<SVGSVGElement | null>(null);
    const selectionAreaRef = useRef<SVGRectElement | null>(null);
    const collectedSeats = useRef<any[]>([]);

    const zoom = (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!widgetMode) return;
        let factor = .3;
        const viewPort = viewPortRef.current;
        let zoom = e.deltaY > 0 ? -1 : 1;
        let matrix = new DOMMatrix();
        if ((scale >= 3.9 && zoom > 0) || (scale <= 0.1 && zoom < 0)) return;

        let scaleV = Number((scale + factor * zoom).toFixed(2));
        offset = {
            x: e.offsetX,
            y: e.offsetY
        };

        if (viewPort && scaleV) {
            onScaleChange!(scaleV);
        }

        matrix.preMultiplySelf(new DOMMatrix()
            .translateSelf(offset.x, offset.y)
            .scaleSelf(scaleV, scaleV)
            .translateSelf(-offset.x, -offset.y));
        setScale(scaleV);

        if (viewPort) {
            viewPort.style.transform = matrix.toString();
        }

    };
    useEventListener("wheel", zoom, containerRef);

    function mouseMove(e: PointerEvent) {
        e.preventDefault();

        if (!onSelectionArea) {
            return;
        }

        //move area by ctrl + mouse
        if (viewPortRef.current && e.buttons === 1 && e.ctrlKey) {
            let matrix = new DOMMatrix();
            const viewPort = viewPortRef.current;

            let tx = e.clientX - offset.x;
            let ty = e.clientY - offset.y;

            matrix.preMultiplySelf(new DOMMatrix()
                .translateSelf(tx, ty))
                .scaleSelf(scale, scale);
            if (viewPort) {
                viewPort.style.transform = matrix.toString();
            }
        }

        //create selection area
        if (containerRef && e.buttons === 1 && !e.ctrlKey && !blockSelection) {
            const isStart = isStartDrawing(e, containerRef, startX, startY);
            //draw selection criteria only when size is more than 6px
            if (!isStart) return;

            drawSelectionArea(e, selectionAreaRef, containerRef, startX, startY);

            if (selectionAreaRef && selectionAreaRef.current) {
                //temporary svg rect to determine intersection area
                const rect = intersectDim(e, containerRef, offset, rectStartX, rectStartY);

                const intersections = containerRef.current.getIntersectionList(rect, null);
                const intersectionsArray = Array.from<SVGSVGElement>(intersections);

                tmpSelection.current = intersectionsArray.map((element: SVGSVGElement) => {
                    if (element.tagName === "text") {
                        return `text:${element.getAttribute("id")}`;
                    } else if (element.tagName === "polyline") {
                        return `polyline:${element.getAttribute("id")}`;
                    } else if (element.tagName === "rect") {
                        return `rect:${element.getAttribute("id")}`;
                    } else if (element.tagName === "circle" && element.getAttribute("type") === "customCircle") {
                        return `circle:${element.getAttribute("id")}`;
                    }

                    return element.getAttribute("id") || "";
                })

                collectedSeats.current = intersectionsArray.map((circle: SVGSVGElement) => {
                    if (circle.getAttribute("type") !== "customCircle") {
                        return data.data[circle.getAttribute("id") || ""];
                    }
                });
            }
        }
    }

    useEventListener("pointermove", mouseMove, containerRef);

    function mouseClickDown(e: PointerEvent) {
        if (onSelectionArea) {
            const parentOffset = containerRef.current.getBoundingClientRect();
            const point = getSVGPoint(e.clientX, e.clientY, containerRef.current);
            offset = {
                x: e.clientX,
                y: e.clientY
            };
            startX = point.x;
            startY = point.y;
            rectStartY = offset.y - parentOffset.top;
            rectStartX = offset.x - parentOffset.left;
        }
    }

    useEventListener("pointerdown", mouseClickDown, containerRef);

    function mouseClickUp(e: PointerEvent) {
        //remove selection area
        const rect = selectionAreaRef.current;
        if (rect) {
            rect.setAttribute("width", "0");
            rect.setAttribute("height", "0");
        }

        //set last user's mouse offset
        offset = {
            x: e.clientX,
            y: e.clientY
        };

        if (!tmpSelection.current.length && !collectedSeats.current.length) return;
        onSelectionArea && onSelectionArea(tmpSelection.current, collectedSeats.current);
        //clear collected seats
        collectedSeats.current = [];
        tmpSelection.current = [];
    }

    useEventListener("pointerup", mouseClickUp, containerRef);

    return (
        <svg
            ref={containerRef}
            width={"100%"}
            height={"100%"}
            viewBox={viewBox}
            preserveAspectRatio="xMinYMin meet"
            onClick={onClick}
            onContextMenu={(e) => {
                e.preventDefault();
                if (typeof onRightClick === "function") {
                    onRightClick(e);
                }
            }}
            style={styles}
        >
            <g id={"viewport"} ref={viewPortRef}>
                {
                    text && Array.isArray(text) && text.map((textItem, index) => {
                        const { properties, styles, rect } = textItem;
                        const id = index.toString();

                        switch (properties.type) {
                            case "text":
                                return (
                                    <text
                                        id={(index).toString()}
                                        onClick={onTextClick}
                                        key={index}
                                        textAnchor="middle"
                                        alignmentBaseline="central"
                                        fontSize={styles.fontSize}
                                        fontWeight={500}
                                        x={rect.x}
                                        y={rect.y}
                                        fill={styles.fill ? styles.fill : "#949494"}
                                        transform={styles.transform}
                                        writingMode={styles.writingMode}
                                        style={{ userSelect: "none" }}
                                    >
                                        {typeof properties.text === 'object'
                                            ? properties.text[preferredLanguage ? preferredLanguage : language as keyof typeof properties.text]
                                            : properties.text}
                                    </text>
                                );

                            case "rect":
                                return (
                                    <rect
                                        onClick={(e) => onRectClick!(e as any)}
                                        id={id}
                                        key={index}
                                        x={rect.x}
                                        y={rect.y}
                                        fill={styles.fill}
                                        width={styles.width}
                                        height={styles.height}
                                        rx={styles.rx}
                                        ry={styles.ry}
                                    />
                                );

                            case "circle":
                                return (
                                    <circle
                                        id={id}
                                        key={index}
                                        type="customCircle"
                                        onClick={(e) => onCircleClick ? onCircleClick(e as any) : null}
                                        cx={rect.x}
                                        cy={rect.y}
                                        r={rect.r}
                                        fill={styles.fill}
                                        stroke={styles.stroke}
                                        strokeWidth={styles.strokeWidth}
                                    />
                                );

                            case "polyline":
                                return (
                                    <polyline
                                        onClick={(e) => onPolylineClick!(e as any)}
                                        id={id}
                                        key={index}
                                        points={rect.points}
                                        stroke={styles.stroke}
                                        strokeWidth={styles.strokeWidth}
                                        fill={styles.fill}
                                    />
                                );

                            default:
                                return <></>;
                        }
                    })
                }

                {
                    data && data.data &&
                    Object.keys(data.data).map((key, i) => {
                        const { rect, id, zone, selected, status, ticket } = data.data[key as keyof typeof data.data];
                        const zoneIndex = zones.map(z => z.id).indexOf(zone || -1);
                        const fill = zoneIndex !== -1 ? zones[zoneIndex].properties.color : "#a6a6a6";
                        if (!rect) {
                            return <></>
                        }
                        return (
                            <MemoizedSeat
                                key={key}
                                x={String(Number(rect.x) + 20)}
                                y={rect.y}
                                id={id}
                                seatKey={key}
                                zone={zone}
                                ticket={ticket}
                                fill={fill}
                                status={status}
                                price={zones[zoneIndex]?.price}
                                isSelected={Boolean(selected)}
                                onClick={onSeatClick}
                                data-testid={`grid-cell-${i}`}
                                showOnly={showOnly}
                                widgetMode={widgetMode}
                                type={type}
                            />
                        );
                    })
                }
            </g>
            {showHints && (
                <g>
                    <rect id={"seatTooltip"} className={classes.rectTooltip} width={0} height={0} fill={"#000"} />
                    <text id={"zone"} className={classes.textTooltip} style={{ fontSize: 10 }} fill={"#fff"} />
                    <text id={"seat"} className={classes.textTooltip} style={{ fontSize: 10 }} fill={"#fff"} />
                    <text id={"price"} className={classes.textTooltip} style={{ fontSize: 10 }} fill={"#fff"} />
                </g>
            )}
            <rect ref={selectionAreaRef} id={"selectionArea"} width="0" height="0" style={{
                fill: "rgba(47,255,255,0.1)",
                stroke: "rgba(42,57,227,0.9)",
                strokeWidth: 1
            }} />
        </svg>
    );
};

export default Schema;

const MemoizedSeat = React.memo(Seat);
