import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionIcon, Paper, LoadingOverlay, Title, Button, Flex, Text, Tooltip, Chip, Badge, Divider } from "@mantine/core";
import { ArrowLeft, Undo, Redo, HelpCircle, Percent } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useGetHallPartsQuery } from "../../../../api/hall";
import PageContainer from "../../../../components/PageContainer";
import Schema from "../../../../components/Schema/Schema";
import { notification } from "../../../../utils/notification";
import HelpModal from "../modal/HelpModal";
import { useLazyReadSchemeQuery, useUpdateSchemeMutation } from "../../../../api/scheme";
import { isErrorWithMessage, showErrorMessages } from "../../../../app/api";
import { HandleonSeatClick, handleRedo, handleSchemaActionClick, handleUndo } from "./utils/SchemeFunctions";
import SetSector from "./panel-options/SetSector";
import ModeTogglePanel from "./panel-options/ModeTogglePanel";
import { useAppSelector } from "../../../../app/hooks";
import { SectorProperties, TextToInsert } from "./interfaces/SchemeEdit.interface";
import TextOptions from "./panel-options/TextOptions";
import { useMouseMove } from "./utils/mouseMove";
import { InsertShape } from "./panel-options/insertShape/insertShape";
import ContextMenu from "./utils/contextMenu";
import { isEqual } from "lodash";

const SchemeViewEdit: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const roles = useAppSelector(state => state.auth.user.role);
    const canEditScheme = roles?.some(role => ["administrators"].includes(role));

    const schemaRef = useRef<HTMLDivElement | null>(null);
    const [readScheme] = useLazyReadSchemeQuery();
    const [updateScheme] = useUpdateSchemeMutation();

    const { data: hallParts, error, isLoading: hallPartsLoading } = useGetHallPartsQuery({
        page: 1,
        page_size: 10
    });

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const [zones, setZones] = useState<any>(null);
    const [text, setText] = useState<any>(null);
    const [textToInsert, setTextToInsert] = useState<TextToInsert>({
        styles: {
            fontSize: 20,
        },
        properties: {
            text: '',
            type: 'text',
        },
        rect: {
            x: '',
            y: ''
        }
    });
    const [widgetMode, setWidgetMode] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(100);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [blockSelection, setBlockSelection] = useState<boolean>(true);
    const [selectItemMode, setSelectItemMode] = useState<boolean>(true);
    const [dotInsertMode, setDotInsertMode] = useState<boolean>(false);
    const [deleteCircle, setDeleteCircle] = useState<boolean>(false);
    const [textInsertMode, setTextInsertMode] = useState<boolean>(false);
    const [shapeInsertMode, setShapeInsertMode] = useState<boolean>(false);
    const [undoStack, setUndoStack] = useState<any[]>([]);
    const [redoStack, setRedoStack] = useState<any[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [sectorProperties, setSectorProperties] = useState<SectorProperties>({ readonly: true });
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const openHelpModal = () => setIsHelpModalOpen(true);
    const closeHelpModal = () => setIsHelpModalOpen(false);
    const [contextMenu, setContextMenu] = useState<{ visible: boolean, x: number, y: number }>({ visible: false, x: 0, y: 0 });

    const handleMouseClick = (e: React.MouseEvent<SVGSVGElement>) => {
        handleSchemaActionClick(
            e,
            data,
            editMode,
            undoStack,
            setUndoStack,
            setData,
            setSelectedKey,
            setText,
            deleteCircle,
            dotInsertMode,
            textInsertMode,
            textToInsert,
            setTextToInsert
        );

        if (contextMenu) {
            setContextMenu({ visible: false, x: 0, y: 0 })
        }

        if (selectedKey) {
            setSelectedKey('');
        }

        if (selectedKeyText) {
            setSelectedKeyText('');
        }

        if (textToInsert.properties.text !== '') {
            setTextToInsert({
                styles: {
                    fontSize: 20,
                    fill: "#949494"
                },
                properties: {
                    text: '',
                    type: 'text',
                },
                rect: {
                    x: '',
                    y: ''
                }
            });
        }
    };

    const [selectedDots, setSelectedDots] = useState<Record<string, any>>([]);
    const [selectedTextFigures, setSelectedTextFigures] = useState<Record<string, any>>([]);
    const [selectedKeyText, setSelectedKeyText] = useState<string>('');
    const [selectedKeyPolilyne, setSelectedKeyPolilyne] = useState<string>('');
    const [selectedKeyRect, setSelectedKeyRect] = useState<string>('');
    const [selectedKeyCustomCircle, setSelectedKeyCustomCircle] = useState<string>('');

    const handleClickEventOnText = (e: React.MouseEvent<SVGTextElement>) => {
        const target = e.target as SVGTextElement;
        const id = target.getAttribute("id");

        deSelectAll();
        setSelectedTextFigures([`text:${id}`]);

        setSelectedKeyText(id!.toString());
        setSelectedKeyPolilyne('');
        setSelectedKeyRect('');
    };

    const handleClickEventOnPolyline = (e: React.MouseEvent<SVGTextElement>) => {
        const target = e.target as SVGTextElement;
        const id = target.getAttribute("id");

        deSelectAll();
        setSelectedTextFigures([`polyline:${id}`]);

        setSelectedKeyPolilyne(id!.toString());
        setSelectedKeyRect('');
        setSelectedKeyText('');
    };

    const handleClickEventOnRect = (e: React.MouseEvent<SVGTextElement>) => {
        const target = e.target as SVGTextElement;
        const id = target.getAttribute("id");

        deSelectAll();
        setSelectedTextFigures([`rect:${id}`]);

        setSelectedKeyRect(id!.toString());
        setSelectedKeyPolilyne('');
        setSelectedKeyText('');
    };

    const handleClickEventOnCircle = (e: React.MouseEvent<SVGTextElement>) => {
        const target = e.target as SVGTextElement;
        const id = target.getAttribute("id");

        deSelectAll();
        setSelectedTextFigures([`circle:${id}`]);

        setSelectedKeyRect('');
        setSelectedKeyPolilyne('');
        setSelectedKeyText('');
        setSelectedKeyCustomCircle(id!.toString());
    };

    const onSeatClick = (id: any, key: any) => {
        HandleonSeatClick(
            id,
            key,
            editMode,
            selectItemMode,
            dotInsertMode,
            deleteCircle,
            data,
            sectorProperties,
            setSectorProperties,
            setSelectedKey,
            setSelectedDots,
            setData,
            undoStack,
            setUndoStack,
            deSelectAll
        )
    }

    const manuallyTriggerSeatClick = () => {
        const key = selectedKey;
        onSeatClick(0, key);
    };

    const fetchSchemeData = async () => {
        if (!id) return;

        setLoading(true);
        try {
            const schemeResponse = await readScheme({ id }).unwrap();
            setData(schemeResponse);
            setZones(schemeResponse.hall_parts);
            setText(schemeResponse.properties.text);
        } catch (error) {
            if (isErrorWithMessage(error)) {
                showErrorMessages(error.data);
            }
            console.error("Error fetching scheme data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateScheme = async () => {
        try {
            if (selectedDots) {
                deSelectAll();
            }

            const payload = {
                data: data.data,
                properties: data.properties
            };

            await updateScheme({ id: data.id, data: payload }).unwrap();

            notification({
                type: "success",
                title: t("scheme.notifications.updated"),
            });
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.error('Error updating scheme:', e);
        }
    };

    const handleSelection = (tmpSelection: string[]) => {
        if (selectedDots) {
            deSelectAll();
        }
        let updatedSelection = tmpSelection.filter(item => item !== 'selectionArea' && item !== 'rect:selectionArea');

        const dots = updatedSelection.filter(item =>
            !item.startsWith("text")
            && !item.startsWith("polyline")
            && !item.startsWith("rect")
            && !item.startsWith('circle:')
        );
        const textFigures = updatedSelection.filter(item =>
            item.startsWith("text")
            || item.startsWith("polyline")
            || item.startsWith("rect")
            || item.startsWith("circle")
        );

        setSelectedDots([...dots]);
        setSelectedTextFigures([...textFigures]);

        setData((prevData: { data: Record<string, any> }) => ({
            ...prevData,
            data: Object.fromEntries(
                Object.entries(prevData.data).map(([key, value]) => {
                    const updatedValue = { ...value };
                    if (dots.includes(key)) {
                        updatedValue.selected = true;
                    }
                    return [key, updatedValue];
                })
            )
        }));
    };

    const deSelectAll = () => {
        if (Object.keys(selectedDots).length > 0) {
            setData((prevData: { data: Record<string, any> }) => ({
                ...prevData,
                data: Object.fromEntries(
                    Object.entries(prevData.data).map(([key, value]) => {
                        const updatedValue = { ...value };
                        delete updatedValue.selected;
                        return [key, updatedValue];
                    })
                )
            }));

            setSelectedDots({});
        }

        setSelectedTextFigures({});
    };

    const handleRightClick = (e: React.MouseEvent) => {
        e.preventDefault();

        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY
        });
    };

    const closeContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0 });
    };

    const handleScaleChange = (newScale: number) => {
        setZoomLevel(newScale * 100);
    };

    useEffect(() => {
        fetchSchemeData();
    }, [id, readScheme]);

    const previousSectorPropertiesRef = useRef<SectorProperties | null>(null);

    // UseEffect to call generation of a sector
    useEffect(() => {
        // Avoid duplicate calls by checking deep equality
        if (!isEqual(previousSectorPropertiesRef.current, sectorProperties) && dotInsertMode) {
            console.warn("Call manuallyTriggerSeatClick for Inserting Sector");
            manuallyTriggerSeatClick();
            previousSectorPropertiesRef.current = sectorProperties;
        }
    }, [sectorProperties, dotInsertMode]);

    useMouseMove(
        schemaRef,
        editMode, selectItemMode, data, setData, setText,
        selectedDots, selectedTextFigures, setBlockSelection
    );

    return (
        <PageContainer>
            <LoadingOverlay
                visible={loading}
                zIndex={14}
                overlayProps={{ radius: "lg", blur: 1 }}
            />

            <HelpModal isOpen={isHelpModalOpen} onClose={closeHelpModal} />

            {editMode && contextMenu.visible && (
                <ContextMenu
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    data={data}
                    setData={setData}
                    setText={setText}
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    editMode={editMode}
                    selectedDots={selectedDots}
                    setSelectedDots={setSelectedDots}
                    selectedTextFigures={selectedTextFigures}
                    setSelectedTextFigures={setSelectedTextFigures}
                    t={t}
                    closeContextMenu={closeContextMenu}
                    deSelectAll={deSelectAll}
                />
            )}

            <Paper
                py={15}
                px={20}
                style={{
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                    border: '2px solid #2c2e33',
                    borderBottom: "none"
                }}
            >
                <Flex w={"100%"} justify={"space-between"}>
                    <Flex gap={10} align={"center"}>
                        <ActionIcon p={2} onClick={() => navigate("/dictionary/schemes/")}>
                            <ArrowLeft size={17} />
                        </ActionIcon>

                        <Title order={4}>
                            {data?.name || "Loading..."}
                        </Title>
                    </Flex>

                    <Button
                        variant="default"
                        radius={0}
                        px={10}
                        onClick={!isHelpModalOpen ? openHelpModal : closeHelpModal}
                    >
                        <Text mr={5}>{t('scheme.redactor.help.btnTitle')}</Text>
                        <HelpCircle size={18} />
                    </Button>
                </Flex>
            </Paper>

            <Flex justify={"space-between"}>
                <Paper
                    w={editMode ? "82%" : "100%"}
                    radius={0}
                    style={{
                        border: '2px solid #2c2e33'
                    }}
                >
                    <Flex
                        justify={"space-between"}
                        p={15}
                    >
                        <Flex gap={10} align={"center"}>
                            {
                                canEditScheme && (
                                    <Chip
                                        color={editMode ? 'red' : 'blue'}
                                        onClick={() => {
                                            if (editMode) {
                                                setBlockSelection(true);
                                                setEditMode(false);
                                                setWidgetMode(false);
                                                deSelectAll();
                                                setSelectedKey('');
                                                setSelectedTextFigures([]);
                                                setSelectedDots({});
                                            } else {
                                                setEditMode(true);
                                                setBlockSelection(false);
                                                setSelectItemMode(true);
                                                setWidgetMode(true);
                                                setDotInsertMode(false);
                                                setDeleteCircle(false);
                                                setTextInsertMode(false);
                                                setShapeInsertMode(false);
                                                setContextMenu({ visible: false, x: 0, y: 0 });
                                            }
                                        }}
                                        variant="filled"
                                    >
                                        {editMode ? t('scheme.redactor.buttons.quitEditMode') : t('scheme.redactor.buttons.enterEditMode')}
                                    </Chip>
                                )
                            }

                            {
                                editMode && (
                                    <>
                                        <Button size="xs" onClick={handleUpdateScheme}>
                                            {t('scheme.saveScheme')}
                                        </Button>
                                    </>
                                )
                            }
                        </Flex>

                        <Flex justify={"space-between"} align={"center"} gap={10}>
                            {editMode ? (
                                <Flex gap={10} align={"center"}>
                                    <Tooltip label={t('scheme.redactor.buttons.undo')}>
                                        <ActionIcon
                                            p={2}
                                            onClick={
                                                () => {
                                                    handleUndo(setUndoStack, setRedoStack, setData, setText, undoStack, data)
                                                }
                                            }
                                            disabled={undoStack.length === 0}
                                        >
                                            <Undo />
                                        </ActionIcon>
                                    </Tooltip>

                                    <Tooltip label={t('scheme.redactor.buttons.redo')}>
                                        <ActionIcon
                                            p={2}
                                            onClick={
                                                () => handleRedo(setUndoStack, setRedoStack, setData, setText, undoStack, redoStack, data)
                                            }
                                            disabled={redoStack.length === 0}
                                        >
                                            <Redo />
                                        </ActionIcon>
                                    </Tooltip>

                                    <Flex>
                                        <Badge size="lg" rightSection={<Percent size={17} />}>{Math.round(zoomLevel)}</Badge>
                                    </Flex>
                                </Flex>
                            ) : null}
                        </Flex>
                    </Flex>

                    <Flex>
                        {
                            editMode && (
                                <ModeTogglePanel
                                    t={t}
                                    deSelectAll={deSelectAll}
                                    setSelectedKeyPolilyne={setSelectedKeyPolilyne}
                                    setSelectedKeyRect={setSelectedKeyRect}
                                    setSelectedKeyCustomCircle={setSelectedKeyCustomCircle}
                                    setSelectedKey={setSelectedKey}
                                    selectItemMode={selectItemMode}
                                    setSelectItemMode={setSelectItemMode}
                                    dotInsertMode={dotInsertMode}
                                    setDotInsertMode={setDotInsertMode}
                                    deleteCircle={deleteCircle}
                                    setDeleteCircle={setDeleteCircle}
                                    textInsertMode={textInsertMode}
                                    setTextInsertMode={setTextInsertMode}
                                    shapeInsertMode={shapeInsertMode}
                                    setShapeInsertMode={setShapeInsertMode}
                                    setBlockSelection={setBlockSelection}
                                />
                            )
                        }

                        <div ref={schemaRef} style={{ width: "100%", height: "100%" }}>
                            <Schema
                                data={data}
                                text={text}
                                zones={zones}
                                blockSelection={blockSelection}
                                type="sales"
                                showHints={true}
                                onSelectionArea={editMode ? handleSelection : () => { }}
                                onClick={handleMouseClick}
                                onSeatClick={onSeatClick}
                                onCircleClick={selectItemMode ? handleClickEventOnCircle : () => { }}
                                onPolylineClick={selectItemMode ? handleClickEventOnPolyline : () => { }}
                                onRectClick={selectItemMode ? handleClickEventOnRect : () => { }}
                                onTextClick={selectItemMode ? handleClickEventOnText : () => { }}
                                viewBox="0 0 1030 750"
                                widgetMode={widgetMode}
                                styles={{
                                    backgroundSize: `${Math.round(zoomLevel)}px ${Math.round(zoomLevel)}px`,
                                    backgroundImage: `linear-gradient(to right, rgba(128, 128, 128, 0.2) 1px, transparent 1px),
                                                  linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 1px, transparent 1px)`
                                }}
                                onRightClick={handleRightClick}
                                showOnly={"sold"}
                                onScaleChange={handleScaleChange}
                            />
                        </div>
                    </Flex>
                </Paper>

                {
                    editMode && (
                        <Paper
                            w={"18%"}
                            radius={0}
                            style={{
                                border: '2px solid #2c2e33',
                                borderLeft: "none"
                            }}
                        >
                            <Title
                                style={{ background: '#2c2e33' }}
                                px={15}
                                py={10}
                                mb={15}
                                order={3}
                            >
                                {t('scheme.redactor.panelOptions.title')}
                            </Title>

                            <Chip
                                px={15}
                                checked={widgetMode}
                                onClick={() => {
                                    if (widgetMode) {
                                        setWidgetMode(false);
                                    } else {
                                        setWidgetMode(true);
                                    }
                                }}
                            >
                                {widgetMode ?
                                    t('scheme.redactor.panelOptions.widgetMode.on') :
                                    t('scheme.redactor.panelOptions.widgetMode.of')
                                }
                            </Chip>
                            {
                                selectedKey &&
                                <SetSector
                                    selectItemMode={selectItemMode}
                                    px={"15px"}
                                    undoStack={undoStack}
                                    setUndoStack={setUndoStack}
                                    data={data}
                                    setData={setData}
                                    selectedDots={selectedDots}
                                    selectedKey={selectedKey}
                                    hallParts={hallParts}
                                    hallPartsLoading={hallPartsLoading}
                                    sectorProperties={sectorProperties}
                                    setSectorProperties={setSectorProperties}
                                    t={t}
                                />
                            }

                            {
                                (textInsertMode || (selectedKeyText !== '')) &&
                                <TextOptions
                                    px={"15px"}
                                    data={data}
                                    setData={setData}
                                    setText={setText}
                                    textInsertMode={textInsertMode}
                                    selectedText={selectedKeyText}
                                    setSelectedText={setSelectedKeyText}
                                    textToInsert={textToInsert}
                                    setTextToInsert={setTextToInsert}
                                    t={t}
                                />
                            }

                            {
                                (
                                    (shapeInsertMode && selectedKeyText === '') ||
                                    (
                                        selectedKeyPolilyne !== '' || selectedKeyRect !== '' ||
                                        selectedKeyCustomCircle !== ''
                                    )
                                )
                                &&
                                <InsertShape
                                    px={"15px"}
                                    selectedKeyPolilyne={selectedKeyPolilyne}
                                    selectedKeyRect={selectedKeyRect}
                                    selectedKeyCustomCircle={selectedKeyCustomCircle}
                                    setSelectedKeyPolilyne={setSelectedKeyPolilyne}
                                    setSelectedKeyRect={setSelectedKeyRect}
                                    setSelectedKeyCustomCircle={setSelectedKeyCustomCircle}
                                    undoStack={undoStack}
                                    setUndoStack={setUndoStack}
                                    data={data}
                                    setData={setData}
                                    setText={setText}
                                    t={t}
                                />
                            }
                        </Paper>
                    )
                }
            </Flex >
        </PageContainer >
    );
};

export default SchemeViewEdit;
