import { api } from "../app/api";
import { IUser } from "./user";


export interface IHall {
    id?: number
    name: string
    building?: number

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export interface IHallPart {
    id: string;
    author: IUser;
    modifier: IUser;
    created: Date;
    modified: Date;
    name: string;
    scheme: number;
}

export interface IHallParts {
    results: IHallPart[]
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IHall[];
}

interface IQueryParams {
    entity?: number;
    building?: number;
    name?: string;

    ordering?: string;
    page?: number;
    page_size?: number;
}

export const hallApi = api.injectEndpoints({
    endpoints: (build) => ({
        getHalls: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/hall/admin/halls/`,
                params: {
                    entity: params.entity,
                    building: params.building
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Hall", tag }]
        }),
        getHallParts: build.query<IHallParts, IQueryParams>({
            query: (params) => ({
                url: "/hall/admin/hall-parts/",
                params: params,
            })
        }),
        createHall: build.mutation<any, IHall>({
            query: (payload) => ({
                url: "/hall/admin/halls/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Hall" }]
        }),
        updateHall: build.mutation<any, IHall & { id: number }>({
            query: (payload) => ({
                url: `/hall/admin/halls/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: [{ type: "Hall" }]
        }),
        readHall: build.query<any, { id: number }>({
            query: (params) => ({
                url: `/hall/admin/halls/${params.id}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "Hall", tag }]
        }),
        removeHall: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/hall/admin/halls/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "Hall" }]
        })
    })
});

export const {
    useCreateHallMutation,
    useUpdateHallMutation,
    useRemoveHallMutation,
    useReadHallQuery,
    useGetHallsQuery,
    useGetHallPartsQuery
} = hallApi;
