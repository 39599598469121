import React from "react";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";


const modules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};
const formats = [
    'header', 'font', 'size', 'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

type TProps = {
    value?: string,
    onChange: (value: string) => void,
    styles: any
}

const QuillTextEditor = ({value, onChange, styles}: TProps) => {
    return (
        <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={value}
            onChange={onChange}
            style={styles}
        />
    )
}
export default QuillTextEditor