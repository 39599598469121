import InfoTabs from "../../../components/CrudModal/InfoTabs";
import InfoItem from "../../../components/CrudModal/InfoItem";
import { useTranslation } from "react-i18next";
import {IContentCategoryRead} from "../../../api/web-content-categories";

const ContentCategoryInfo = ({ record, isFetching }: { record?: IContentCategoryRead, isFetching: boolean }) => {
    const { t } = useTranslation();
    return (
        <InfoTabs isFetching={isFetching} record={record}>
            {(!isFetching && record) &&  (
                <>
                    <InfoItem label={t("halls.form.name")} value={record.code} />
                    <InfoItem label={t("halls.form.name")} value={record.name} />
                </>
            )}
        </InfoTabs>
    );
};

export default ContentCategoryInfo;
