import { useState, useEffect, useRef } from "react";

export const useMouseMove = (
    schemaRef: React.RefObject<HTMLDivElement>,
    editMode: boolean,
    selectItemMode: boolean,
    data: any,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setText: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    selectedDots: Record<string, any>,
    selectedTextFigures: Record<string, any>,
    setBlockSelection: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const [isDragging, setIsDragging] = useState(false);
    const dragOffsets = useRef<{ [key: string]: { x: number; y: number } }>({});

    const initOffsets = (event: MouseEvent) => {
        const newOffsets: { [key: string]: { x: number; y: number } } = {};

        for (const key of Object.keys(selectedDots)) {
            const dot = data.data[selectedDots[key]];
            if (dot?.rect) {
                newOffsets[selectedDots[key]] = {
                    x: event.clientX - Number(dot.rect.x),
                    y: event.clientY - Number(dot.rect.y),
                };
            }
        }

        for (const key of Object.keys(selectedTextFigures)) {
            const index = selectedTextFigures[key].split(":")[1];
            const figure = data.properties.text?.[index];

            if (figure?.rect) {
                if (figure.properties.type === "polyline") {
                    const points = figure.rect.points.split(" ").map((point: string) => {
                        const [x, y] = point.split(",").map(Number);
                        return { x, y };
                    });

                    if (points.length > 0) {
                        newOffsets[index] = {
                            x: event.clientX - points[0].x,
                            y: event.clientY - points[0].y,
                        };
                    }
                } else {
                    newOffsets[index] = {
                        x: event.clientX - Number(figure.rect.x),
                        y: event.clientY - Number(figure.rect.y),
                    };
                }
            }
        }

        return newOffsets;
    };

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            if (!editMode || (Object.keys(selectedDots).length === 0 && Object.keys(selectedTextFigures).length === 0)) return;

            // Check if the mouse is inside the Schema component
            if (!schemaRef.current?.contains(event.target as Node)) return;

            setIsDragging(true);
            setBlockSelection(true);
            document.body.style.cursor = "move";

            dragOffsets.current = initOffsets(event);
            event.preventDefault();
        };

        const handleMouseMove = (event: MouseEvent) => {
            // Check if the mouse is inside the Schema component
            if (!schemaRef.current?.contains(event.target as Node)) return;

            if (!isDragging) return;

            setData((prevData: any) => {
                const updatedData = JSON.parse(JSON.stringify(prevData));

                for (const key of Object.keys(selectedDots)) {
                    const index = selectedDots[key];
                    const offset = dragOffsets.current[index];
                    if (offset && updatedData.data[index]?.rect) {
                        updatedData.data[index].rect.x = (event.clientX - offset.x).toString();
                        updatedData.data[index].rect.y = (event.clientY - offset.y).toString();
                    }
                }

                for (const key of Object.keys(selectedTextFigures)) {
                    const index = selectedTextFigures[key].split(":")[1];
                    const offset = dragOffsets.current[index];
                    const figure = updatedData.properties.text?.[index];

                    if (figure) {
                        if (figure.properties.type === "polyline" && figure.rect.points) {
                            const points = figure.rect.points.split(" ").map((point: string) => {
                                const [x, y] = point.split(",").map(Number);
                                return { x, y };
                            });

                            if (points.length > 0 && offset) {
                                const newPoints = points.map(({ x, y }: any) => ({
                                    x: event.clientX - offset.x + (x - points[0].x),
                                    y: event.clientY - offset.y + (y - points[0].y),
                                }));

                                figure.rect.points = newPoints.map(({ x, y }: any) => `${x},${y}`).join(" ");
                            }
                        } else if (offset && figure.rect) {
                            figure.rect.x = (event.clientX - offset.x).toString();
                            figure.rect.y = (event.clientY - offset.y).toString();
                        }
                    }
                }

                setText(updatedData.properties.text);
                return updatedData;
            });
        };

        const handleMouseUp = () => {
            if (editMode && selectItemMode) setBlockSelection(false);
            setIsDragging(false);
            dragOffsets.current = {};
            document.body.style.cursor = "default";
        };

        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, selectedDots, selectedTextFigures]);

    return null;
};
