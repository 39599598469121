import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetEventsQuery, useReadEventQuery, useUpdateEventMutation } from "../../api/event";
import {
    ActionIcon,
    Divider,
    Flex,
    Paper,
    Select,
    Text,
    Image,
    FileButton,
    Button,
    Switch, TextInput, Box,
    Tooltip
} from "@mantine/core";
import dayjs from "dayjs";
import { ArrowLeft, FileX, ImageIcon, RectangleHorizontal, RectangleVertical, Save, YoutubeIcon } from "lucide-react";
import {
    useCreateEventContentMutation, useGetEventContentQuery,
    useRemoveEventContentMutation, useUpdateEventContentMutation
} from "../../api/event-content";
import { isErrorWithMessage, showErrorMessages } from "../../app/api";
import { MediaUrl, useUploadMutation } from "../../api/files";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { useConfirmationModal } from "../../hooks/useConfirmationModal";
import { notification } from "../../utils/notification";
import QuillTextEditor from "../../components/QuillTextEditor";
import EventInputSelect from "../../components/EventInputSelect";

function getId(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? match[2]
        : null;
}
const Content = () => {
    const { t, i18n } = useTranslation();
    const [ConfirmModal, confirmDelete] = useConfirmationModal({});
    const currentLng = i18n.language === "ru" ? "ru" : "ro";
    const params = useParams();
    const navigate = useNavigate();
    const [language, setLanguage] = useState<'ro' | 'ru' | 'en'>(currentLng);
    const [contentId, setContentId] = useState<number | null>(null);
    const [inSlider, setInSlider] = useState<boolean>(false);
    const [allowInternetSales, setAllowInternetSales] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");

    const [imageHorizontal, setImageHorizontal] = useState<File | null>(null);
    const [imageHorizontalUrl, setImageHorizontalUrl] = useState<string>('');

    const [imagePortrait, setImagePortrait] = useState<File | null>(null);
    const [imagePortraitUrl, setImagePortraitUrl] = useState<string>('');

    const [video, setVideo] = useState<string>('');

    const [createEventContent] = useCreateEventContentMutation();
    const [updateEventContent] = useUpdateEventContentMutation();
    const [updateEvent] = useUpdateEventMutation();
    const [removeEventContent] = useRemoveEventContentMutation();
    const [uploadFile] = useUploadMutation();
    const { data: eventRecord, isFetching: isFetchingEvent } = useReadEventQuery({ id: Number(params.id || 0) }, {
        skip: !params.id
    });

    const { data: dataContent = { results: [] } } = useGetEventContentQuery({
        event: Number(params.id),
        language: language,
    }, {
        skip: !params.id
    })
    const { data = { results: [] } } = useGetEventsQuery({
        page: 1,
        page_size: 9999,
        ordering: "start",
        start_gte: dayjs(new Date()).format("YYYY-MM-DDT00:00:00")
    });
    const isSmallScreen = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        if (dataContent.results.length) {
            const content = dataContent.results[0]
            setDescription(content.description || "");
            setContentId(content.id);

            return
        }
        setDescription("");
        setContentId(null);

    }, [JSON.stringify(dataContent.results)])

    useEffect(() => {
        if (eventRecord) {
            eventRecord.image ?
                setImageHorizontalUrl(import.meta.env.VITE_BACKEND_URL + eventRecord.image) : setImageHorizontalUrl("")
            eventRecord.portrait_image ?
                setImagePortraitUrl(import.meta.env.VITE_BACKEND_URL + eventRecord.portrait_image) : setImagePortraitUrl("")
            setAllowInternetSales(Boolean(eventRecord.allow_internet_sales))
            setVideo(eventRecord.video || "")
            setInSlider(Boolean(eventRecord.slider))
        }
    }, [JSON.stringify(eventRecord)]);

    function onChangeEvent(event: string) {
        navigate("/events/content/" + event)
    }

    async function saveContent() {
        try {
            if (!params.id) return;

            let imageHorizontalRes: MediaUrl | null = null;
            let imagePortraitRes: MediaUrl | null = null;

            if (imageHorizontal) {
                try {
                    imageHorizontalRes = await uploadFile({
                        event: Number(params.id),
                        type: 'event_content',
                        image: imageHorizontal,
                        convert_image: 'webp'
                    }).unwrap();

                } catch (e) {
                    if (isErrorWithMessage(e)) {
                        showErrorMessages(e.data);
                    }
                    console.log(e);
                    return
                }
            }

            if (imagePortrait) {
                try {
                    imagePortraitRes = await uploadFile({
                        event: Number(params.id),
                        type: 'event_content',
                        image: imagePortrait,
                        convert_image: 'webp'
                    }).unwrap();

                } catch (e) {
                    if (isErrorWithMessage(e)) {
                        showErrorMessages(e.data);
                    }
                    console.log(e);
                    return
                }
            }


            const data = {
                event: Number(params.id),
                language,
                description: description,
                image: imageHorizontalRes ? imageHorizontalRes.id : undefined,
                portrait_image: imagePortraitRes ? imagePortraitRes.id : undefined,
            }
            contentId ? await updateEventContent({
                ...data,
                id: contentId
            }).unwrap() : await createEventContent(data).unwrap();

            await updateEvent({
                ...eventRecord,
                image: imageHorizontalRes ? imageHorizontalRes.id : undefined,
                portrait_image: imagePortraitRes ? imagePortraitRes.id : undefined,
                allow_internet_sales: allowInternetSales,
                slider: inSlider,
                video: video ? video : null,
            })

            notification({ type: "success", title: "Конент сохранен" });
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    async function removeContent() {
        try {
            const isConfirmed = await confirmDelete();
            if (isConfirmed && contentId) {
                await removeEventContent({ id: contentId }).unwrap();
                notification({ type: "success", title: "Конент удален" });
            }
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    return (
        <>
            <Paper mb={10} p={10} px={20}>
                <Flex
                    justify={isSmallScreen ? "space-around" : "space-between"}
                    align={"center"}
                    wrap={"wrap"}
                >
                    <Flex align={"center"}>
                        <ActionIcon onClick={() => navigate("/events")}>
                            <ArrowLeft />
                        </ActionIcon>

                        <Flex ml={10}>
                            <Text>
                                {eventRecord?.building?.name},
                                {" " + eventRecord?.hall?.name},
                                {" " + dayjs(eventRecord?.start).format('DD.MM.YYYY')}
                            </Text>
                        </Flex>

                        <Box
                            ml={15}
                        >
                            <EventInputSelect
                                onChange={onChangeEvent}
                                value={String(eventRecord?.id)}
                                data={data.results}
                            />
                        </Box>
                    </Flex>

                    <Flex
                        mt={isSmallScreen ? 10 : 0}
                    >
                        <Flex>
                            <Tooltip label={t('common.save')}>
                                <Button
                                    onClick={saveContent}
                                    radius={0}
                                >
                                    <Save />
                                </Button>
                            </Tooltip>

                            <Tooltip label={t('common.remove')}>
                                <Button
                                    onClick={removeContent}
                                    variant={'outline'}
                                    color={'red.5'}
                                    disabled={!Boolean(contentId)}
                                    radius={0}
                                >
                                    <FileX />
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Flex>
                </Flex>
            </Paper>

            <Paper p={10}>
                <Flex
                    justify={"space-between"}
                    p={10}
                    wrap={"wrap"}
                >
                    <Paper
                        p={10}
                        w={isSmallScreen ? '100%' : '70%'}
                    >
                        <Text pb={10}>{t("events.content.title")}</Text>
                        <Divider />

                        <Flex
                            align={"center"}
                            justify={"space-between"}
                            wrap={"wrap"}
                            p={10}
                            style={{
                                borderLeft: "2px solid #373a40",
                                borderRight: "2px solid #373a40"
                            }}
                        >
                            <Flex
                                gap={5}
                                justify={"flex-start"}
                            >
                                <Switch
                                    checked={inSlider}
                                    onChange={(e) => setInSlider(e.target.checked)}
                                    label={t("events.content.showInBanner")}
                                />
                                <Switch
                                    checked={allowInternetSales}
                                    onChange={(e) => setAllowInternetSales(e.target.checked)}
                                    label={t("events.content.launchSales")}
                                />

                            </Flex>

                            <LanguageChanger
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Flex>

                        <QuillTextEditor value={description} onChange={setDescription} styles={{
                            height: 500,
                            marginBottom: 50
                        }} />
                    </Paper>

                    <Paper
                        w={isSmallScreen ? '100%' : '30%'}
                        p={10}
                    >
                        <Flex
                            justify={"space-between"}
                            gap={10}
                            wrap={"wrap"}
                            mt={isSmallScreen ? 30 : 0}
                        >
                            <ImageUploaderHorizontal
                                file={imageHorizontal ? URL.createObjectURL(imageHorizontal) : imageHorizontalUrl}
                                upload={setImageHorizontal}
                            />
                            <ImageUploaderPortrait
                                file={imagePortrait ? URL.createObjectURL(imagePortrait) : imagePortraitUrl}
                                upload={setImagePortrait}
                            />
                        </Flex>

                        <Box>
                            <TextInput
                                mt={15}
                                label={t('events.content.linkVideo')}
                                value={video}
                                onChange={v => setVideo(v.target.value)}
                                leftSection={<YoutubeIcon />}
                            />
                            {
                                video && (
                                    <Box mt={15}>
                                        <iframe
                                            id="videoObject"
                                            src={`//www.youtube.com/embed/${getId(video)}`}
                                            width="100%"
                                            height={250}
                                            title="video"
                                            style={{
                                                border: 'none'
                                            }}
                                            allowFullScreen></iframe>
                                    </Box>
                                )
                            }
                        </Box>
                    </Paper>
                </Flex>
            </Paper>
            <ConfirmModal />
        </>
    );
};

export default Content;


const ImageUploaderHorizontal = ({ file, upload }: { file: string | null, upload: (file: File) => void }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Flex justify={'center'} mt={10} style={{ border: '1px solid #d4d4d4', borderRadius: 8 }}>
                {file && file.length ? (
                    <Image
                        h={150}
                        w={220}
                        radius="md"
                        src={file}
                    />
                ) : (
                    <Flex
                        direction={'column'}
                        align={'center'}
                        justify={'center'}
                        h={150}
                        w={220}
                        p={10}
                    >
                        <ImageIcon size={100} color={'#bdbdbd'} />
                        <Text
                            ta={'center'}
                            size={'xs'}
                            c={'dimmed'}
                        >
                            {t('common.data_not_found')}
                        </Text>
                    </Flex>
                )}
            </Flex>
            <Paper py={10} w={'100%'}>
                <Flex justify={'center'}>
                    <FileButton onChange={upload} accept="image/png,image/jpeg">
                        {(props) => <Button
                            size={'xs'}
                            leftSection={<RectangleHorizontal />} {...props} variant={"outline"}
                            color={"orange"}>{t('common.upload')}</Button>}
                    </FileButton>
                </Flex>
            </Paper>
        </div>
    )
}

const ImageUploaderPortrait = ({ file, upload }: { file: string | null, upload: (file: File) => void }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Flex justify={'center'} mt={10} style={{ border: '1px solid #d4d4d4', borderRadius: 8 }}>
                {file && file.length ? (
                    <Image
                        w={120}
                        h={150}
                        radius="md"
                        src={file}
                    />
                ) : (
                    <Flex
                        direction={'column'}
                        align={'center'}
                        justify={'center'}
                        h={150}
                        p={10}
                    >
                        <ImageIcon
                            size={100}
                            color={'#bdbdbd'}
                        />
                        <Text
                            ta={'center'}
                            size={'xs'}
                            c={'dimmed'}
                        >
                            {t('common.data_not_found')}
                        </Text>
                    </Flex>
                )}
            </Flex>
            <Paper py={10} w={'100%'}>
                <Flex justify={'center'}>
                    <FileButton onChange={upload} accept="image/png,image/jpeg">
                        {(props) => <Button {...props}
                            size={'xs'}
                            leftSection={<RectangleVertical />}
                            variant={"outline"} color={"orange"}
                        >
                            {t('common.upload')}
                        </Button>}
                    </FileButton>
                </Flex>
            </Paper>
        </div>
    )
}

const LanguageChanger = ({
    setLanguage,
    language
}: { setLanguage: (lng: 'ro' | 'ru' | 'en') => void, language: 'ro' | 'ru' | 'en' }) => {
    return (
        <Select
            value={language}
            placeholder="Язык"
            onChange={setLanguage}
            data={['ru', 'ro', 'en']}
            radius={0}
            w={'30%'}
        />
    )
}
export { LanguageChanger }