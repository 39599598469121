import { useParams } from "react-router-dom";
import { isErrorWithMessage, returnErrorMessage, showErrorMessages } from "../../app/api";
import { useEffect, useRef, useState } from "react";
import {
    IEventRead,
    IValidateTicketResponse,
    useLazyReadEventQuery,
    useValidateTicketMutation
} from "../../api/event";
import { Box, Button, Card, Flex, LoadingOverlay, Modal, Progress, Text } from "@mantine/core";
import QrReader from "../../components/qr-reader/QrReader";
import dayjs from "dayjs";
import { useLazyTicketStatisticsQuery } from "../../api/analytics";
import scanned_success_sound from './scanned_success_sound.mp3'
import scanned_error_sound from './scanned_error_sound.mp3'
import { useTranslation } from "react-i18next";
import { AlertCircleIcon, CheckCircle } from "lucide-react";
import './ticket-validation.css';
import React from "react";
import { useMediaQuery } from "@mantine/hooks";

const audio_success = new Audio(scanned_success_sound);
const audio_error = new Audio(scanned_error_sound);

const TicketValidation = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 700px)');
    const isMaxHeight700 = useMediaQuery('(max-height: 700px)');

    const params = useParams();
    const cameraRef = useRef<{ preferredCamera: string }>();
    const [validateTicketRq] = useValidateTicketMutation();
    const [readEvent] = useLazyReadEventQuery();
    const [event, setEvent] = useState<IEventRead>()

    const [getStatistics] = useLazyTicketStatisticsQuery();

    // Result
    const [scannedResult, setScannedResult] = useState<string | undefined>("");
    const [scannedError, setScannedError] = useState<string | undefined | string[]>("");
    const [validatedResult, setValidatedResult] = useState<IValidateTicketResponse | undefined>();
    const [validatedResultArray, setValidatedResultArray] = useState<IValidateTicketResponse[] | undefined>();
    const [validatedToken, setValidatedToken] = useState<string | undefined>(undefined)
    const [seats, setSeats] = useState({ total: 0, used: 0, percentage: 0 })
    const [isPaused, setIsPaused] = useState<boolean>(false);

    useEffect(() => {
        if (params.id) {
            readEvent({ id: Number(params.id) }).then(res => setEvent(res.data))
            checkSeats()
        }
    }, [params.id]);

    useEffect(() => {
        if (scannedResult && params.id && !isPaused) {
            validateSeat(scannedResult, Number(params.id));
        }
    }, [scannedResult, params.id, validatedToken, isPaused])

    async function validateSeat(token: string, eventId: number) {
        const activeCamera = cameraRef.current?.preferredCamera;
        try {
            //do not validate previous scanned ticket
            if (validatedToken === token) return;

            //play sound success scanned qr code
            await audio_success.play();

            //set scan pause
            if (activeCamera) {
                setIsPaused(true)
            }

            //validate ticket on backend
            const res = await validateTicketRq({ event: eventId, token }).unwrap();

            //set validated ticket, set scanned token
            setValidatedToken(token)
            setValidatedResult(res)
            setScannedError('');

            //re-count stats
            checkSeats();
        } catch (e) {
            await audio_error.play(); //play error sound
            if (isErrorWithMessage(e)) {
                const errors = returnErrorMessage(e.data);
                if (activeCamera) {
                    setIsPaused(true)
                }
                setValidatedToken(token)
                setValidatedResult(undefined)
                setScannedError(errors)
            }
        }
    }

    async function checkSeats() {
        try {
            const statistics = await getStatistics({ event: Number(params.id) }).unwrap();
            const soldTickets = statistics.filter((f: any) => f.status === "sold").length;
            const usedSeats = statistics.filter((f: any) => f.status === "used").length;
            setSeats({
                total: soldTickets,
                used: usedSeats,
                percentage: (100 * (usedSeats)) / soldTickets
            })
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    function confirmScannedResult() {
        setIsPaused(false);

        //modal animation delay
        setTimeout(() => {
            setValidatedResult(undefined)
            setScannedError('');
        }, 100)

        let maxArraySize = isMaxHeight700 ? 5 : 6;

        if (validatedResult) {
            setValidatedResultArray(prevArray => {
                const newArray = prevArray ? [validatedResult, ...prevArray] : [validatedResult];
                return newArray.length >= maxArraySize ? newArray.slice(0, maxArraySize) : newArray;
            });
        }
    }

    if (!event) return (
        <Flex
            gap={10}
            direction={{ base: 'column', xs: 'row', sm: "row", lg: 'row' }}
            h={'calc(100vh - 90px)'}
        >
            <Card
                w={{ sm: '100%', lg: "calc(100% - 300px)" }}
                h={isSmallScreen ? '256px' : '100%'}
            >
                <LoadingOverlay visible={true} />
            </Card>

            <Card
                w={{ sm: '100%', xs: "50%", lg: '300px' }}
                h={'100%'}
            >
                <LoadingOverlay visible={true} />
            </Card>
        </Flex>
    )

    return (
        <Flex
            gap={10}
            direction={{ base: 'column', xs: 'row', sm: "row", lg: 'row' }}
            h={'calc(100vh - 90px)'}
        >
            <Card
                w={{ sm: '100%', lg: "calc(100% - 300px)" }}
                h={{ sm: '90%', lg: '100%' }}
            >
                <QrReader
                    onScanSuccess={setScannedResult}
                    onScanFail={setScannedResult}
                    paused={false}
                    cameraRef={cameraRef}
                />
            </Card>

            <Card
                w={{ sm: '100%', xs: "50%", lg: '300px' }}
                px={15}
                h={{ base: 'calc(100% - 300px)', xs: "100%", md: '100%' }}
            >
                <Flex
                    gap={10}
                    direction={{ base: 'column', sm: 'column', md: "column", lg: 'column' }}
                    h={'100%'}
                    w={'100%'}
                >
                    <Flex
                        direction={"column"}
                    >
                        <Text size={"lg"} fw={'bolder'}
                            style={{
                                overflow: "hidden",
                                display: '-webkit-box',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': "2",
                                'line-clamp': 2
                            }}
                        >
                            {event.name}
                        </Text>

                        <Text size={"md"} pt={5}>{dayjs(event.start).format("DD MMMM YYYY HH:mm")}</Text>
                    </Flex>

                    <Box
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Progress
                            value={seats.percentage}
                            striped
                            h={22}
                            radius={0}
                        />

                        <Flex
                            w={'100%'}
                            justify={"space-between"}
                            align={"baseline"}
                            px={5}
                            style={{
                                position: 'absolute',
                                top: -4
                            }}
                        >
                            <Text
                                mr={20}
                                size={"lg"}
                                c={"white"}
                            >
                                {t("seatValidate.fillLevel")}
                            </Text>

                            <Text
                                c={"white"}
                                size={'sm'}
                                ta={'center'}
                                fw={"bold"}
                            >
                                {seats.used} / {seats.total}
                            </Text>
                        </Flex>
                    </Box>

                    <Box>
                        {validatedResultArray && validatedResultArray.map((validatedResult, index) => (
                            <Box
                                key={index}
                                style={{
                                    borderLeft: '4px solid #099268',
                                    borderTopLeftRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                    paddingLeft: '6px'
                                }}
                            >
                                <Text size="sm" mb="xs" fw={500} mt={index != 0 ? 11 : 0}>
                                    <span>
                                        {validatedResult.id}
                                        <span className="ticket-slash-spacing">/</span>
                                        {validatedResult?.key.split(':')[0]}
                                        <span className="ticket-slash-spacing">/</span>
                                        {t("seatValidate.columns.seat")} {validatedResult?.key.split(':')[1]}
                                        <span className="ticket-slash-spacing">/</span>
                                        {t("seatValidate.columns.row")} {validatedResult?.key.split(':')[2]}
                                    </span>
                                </Text>
                            </Box>
                        ))}
                    </Box>
                </Flex>
            </Card>

            <Modal
                opened={Boolean(isPaused && (validatedResult || scannedError))}
                onClose={confirmScannedResult}
                centered={true}
            >
                <Box>
                    {scannedError && (
                        <Flex align={"center"} direction={"column"}>
                            <AlertCircleIcon color="red" size={100} />
                            <Text mt={10} c={"red"} ml={10}>{scannedError}</Text>
                        </Flex>
                    )}

                    {validatedResult && (
                        <>
                            <Flex justify={"center"}>
                                <CheckCircle color="#099268" size={100} />
                            </Flex>

                            <Box mt={10}>
                                <Text size="sm" mb="xs" fw={500}>
                                    <span>
                                        {validatedResult?.key.split(':')[0]}
                                        <span className="ticket-slash-spacing">/</span>
                                        {t("seatValidate.columns.seat")} {validatedResult?.key.split(':')[1]}
                                        <span className="ticket-slash-spacing">/</span>
                                        {t("seatValidate.columns.row")} {validatedResult?.key.split(':')[2]}
                                    </span>
                                </Text>
                                <Text size="sm" mb="xs" fw={500}>
                                    {t("seatValidate.columns.price")} {validatedResult?.amount}
                                </Text>
                            </Box>
                        </>
                    )}
                </Box>

                <Button
                    w={"100%"}
                    mt={15}
                    size="lg"
                    color={validatedResult ? "#099268" : "red"}
                    onClick={confirmScannedResult}
                >
                    {t("common.confirmation")}
                </Button>
            </Modal>
        </Flex>
    )
}

export default React.memo(TicketValidation);