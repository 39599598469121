import {
    IContentCategoryRead,
    useGetContentCategoriesQuery,
    useReadContentCategoryQuery, useRemoveContentCategoryMutation
} from "../../../api/web-content-categories";
import React, {useEffect, useState} from "react";
import {ActionIcon, Flex} from "@mantine/core";
import {PencilIcon} from "lucide-react";
import DefaultTable from "../../../components/DefaultTable/DefaultTable";
import {useTranslation} from "react-i18next";
import {createFormContext} from "@mantine/form";
import CrudModal from "../../../components/CrudModal/CrudModal";
import {notification} from "../../../utils/notification";
import {isErrorWithMessage, showErrorMessages} from "../../../app/api";
import ContentCategoryForm from "./ContentCategoryForm";
import ContentCategoryInfo from "./ContentCategoryInfo";

const page_size = 10;
const initialModalState = {
    opened: false,
    inEdit: false
};
export const [FormProvider, useWebCategoryFormContext, useWebCategoryForm] =
    createFormContext<IContentCategoryRead>();
const ContentCategoryPage = () => {
    const {t} = useTranslation();
    const [page, setPage] = useState(1);
    const [modalState, setModalState] = useState<{ opened: boolean, record?: "", inEdit: boolean }>(initialModalState);
    const [removeContentCategory] = useRemoveContentCategoryMutation();
    const form = useWebCategoryForm({
        initialValues: {
            code: "",
            name: "",
        }
    });

    const {data = {results: [], count: 0}, isFetching} = useGetContentCategoriesQuery({
        page,
        page_size: 10
    });
    const {data: record, isFetching: isFetchingRecord} = useReadContentCategoryQuery({
            code: modalState.record
        },
        {skip: !modalState.record});

    async function onDelete() {
        try {
            modalState.record && await removeContentCategory({code: modalState.record}).unwrap();
            notification({type: "success", title: t("content_category.notifications.deleted")});
            setModalState(initialModalState);
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
            console.log(e);
        }
    }

    useEffect(() => {
        if (modalState.record && modalState.inEdit) {
            console.log(record)
            form.setValues({
                ...record,
            });
        }
    }, [modalState.record, modalState.inEdit, record]);
    return (
        <>
            <DefaultTable
                data={{
                    ...data,
                    totalPages: Math.ceil(data.count / page_size)
                }}
                columns={[
                    {
                        label: t("content_category.form.code"), name: "code", render: (_, record) => {
                            return (
                                <Flex align={"center"}>
                                    {record.code}
                                </Flex>
                            );
                        }
                    },
                    {
                        label: t("content_category.form.name"), name: "name", render: (_, record) => {
                            return (
                                <Flex align={"center"}>
                                    {record.name}
                                </Flex>
                            );
                        }
                    },
                    {
                        name: "actions",
                        label: t("common.actions"),
                        type: "actions",
                        width: "50px",
                        render: (_, record) => {
                            return (
                                <ActionIcon variant={"transparent"} color={"dark.1"}
                                            onClick={() => setModalState({
                                                opened: true,
                                                record: record.code,
                                                inEdit: false
                                            })}
                                >
                                    <PencilIcon size={16}/>
                                </ActionIcon>
                            );
                        }
                    }
                ]}
                isFetching={isFetching}
                onChangePage={setPage}
                page={page}
                creatable
                onSearch={() => {
                }}
                enableSearch
                onClickCreate={() => setModalState({opened: true, inEdit: true})}
                createTitle={t("content_category.create")}
            />

            <CrudModal
                type={"drawer"}
                title={modalState.record ? record?.name : t("content_category.create")}
                opened={modalState.opened}
                formId={"webCategoryForm"}
                isUpdate={Boolean(modalState.record)}
                onClose={() => {
                    setModalState(initialModalState);
                    form.reset();
                }}
                onDelete={onDelete}
                inEdit={modalState.inEdit}
                setEdit={(isEdit) => {
                    setModalState(prevState => {
                        return {
                            ...prevState,
                            inEdit: isEdit
                        };
                    });
                }}
            >
                <FormProvider form={form}>
                    {modalState.inEdit ? (
                        <ContentCategoryForm
                            onClose={() => {
                                setModalState(prevState => {
                                    return {
                                        ...prevState,
                                        inEdit: !Boolean(prevState.record),
                                        opened: Boolean(prevState.record)
                                    };
                                });
                            }}
                            recordId={modalState.record}
                        />
                    ) : (modalState.opened && !modalState.inEdit) && (
                        <ContentCategoryInfo record={record} isFetching={isFetchingRecord}/>
                    )}
                </FormProvider>
            </CrudModal>
        </>
    )
}

export default ContentCategoryPage