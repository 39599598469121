import { Menu, Text, Title } from "@mantine/core";
import { TrashIcon } from "lucide-react";
import { useEffect } from "react";

interface ContextMenuProps {
    undoStack: any[];
    setUndoStack: React.Dispatch<React.SetStateAction<any[]>>;
    data: any,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setText: React.Dispatch<React.SetStateAction<any>>,
    contextMenu: { visible: boolean; x: number; y: number };
    setContextMenu: React.Dispatch<React.SetStateAction<any>>,
    editMode: boolean;
    selectedDots: Record<string, any>;
    setSelectedDots: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    selectedTextFigures: Record<string, any>;
    setSelectedTextFigures: React.Dispatch<React.SetStateAction<string[]>>;
    t: (key: string) => string;
    closeContextMenu: () => void;
    deSelectAll: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
    undoStack,
    setUndoStack,
    data,
    setData,
    setText,
    contextMenu,
    setContextMenu,
    editMode,
    selectedDots,
    setSelectedDots,
    selectedTextFigures,
    setSelectedTextFigures,
    t,
    closeContextMenu,
    deSelectAll,
}) => {
    if (!editMode || !contextMenu.visible) return null;

    const handleDeleteSelectedElements = () => {
        setUndoStack([...undoStack, data]);
        const updatedData = JSON.parse(JSON.stringify(data));

        // 1. Handle deletion of seats from `selectedDots`
        if (selectedDots && selectedDots.length > 0) {
            Object.keys(updatedData.data).forEach((key) => {
                if (selectedDots.includes(key)) {
                    delete updatedData.data[key];
                }
            });
        }

        // 2. Handle deletion of text, rect or polyline from `selectedTextFigures`
        if (selectedTextFigures.length > 0) {
            const indicesToRemove = selectedTextFigures
                .map((key: { split: (arg0: string) => [any, any]; }) => {
                    const [type, index] = key.split(":");
                    return { type, index: Number(index) };
                })
                .filter(({ index }: any) => !isNaN(index))
                .sort((a: { index: number; }, b: { index: number; }) => b.index - a.index);

            indicesToRemove.forEach(({ index }: any) => {
                if (index >= 0 && index < updatedData.properties.text.length) {
                    updatedData.properties.text.splice(index, 1);
                }
            });
        }

        setData(updatedData);
        setText(updatedData.properties.text);
        setSelectedDots([]);
        setSelectedTextFigures([]);
        setContextMenu({ visible: false, x: 0, y: 0 });
    };

    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();

            setContextMenu({
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        };

        window.addEventListener("contextmenu", handleContextMenu);

        return () => {
            window.removeEventListener("contextmenu", handleContextMenu);
        };
    }, [setContextMenu]);

    return (
        <Menu
            opened={contextMenu.visible}
            onClose={closeContextMenu}
            position="bottom-start"
            withArrow
            withinPortal
            styles={{
                dropdown: {
                    position: "fixed",
                    left: `${contextMenu.x}px`,
                    top: `${contextMenu.y}px`,
                    zIndex: 50,
                    width: 300,
                },
            }}
        >
            <Menu.Dropdown>
                <Menu.Label>
                    {
                        (
                            (selectedDots && Object.keys(selectedDots).length !== 0) ||
                            (selectedTextFigures && Object.keys(selectedTextFigures).length !== 0)
                        ) ?
                            <Title order={5}>{t("scheme.redactor.contextMenu.labels.elementsSelected")}</Title>
                            :
                            <Text>{t("scheme.redactor.contextMenu.labels.elementsNotSelected")}</Text>
                    }
                </Menu.Label>
                {
                    (
                        (selectedDots && Object.keys(selectedDots).length !== 0) ||
                        (selectedTextFigures && Object.keys(selectedTextFigures).length !== 0)
                    )
                    &&
                    <>
                        <Menu.Item onClick={deSelectAll}>
                            {t("scheme.redactor.contextMenu.buttons.deSelectDots")}
                        </Menu.Item>

                        <Menu.Item onClick={handleDeleteSelectedElements} color="red" leftSection={<TrashIcon size={20} />}>
                            {t("scheme.redactor.contextMenu.buttons.deleteSelectElements")}
                        </Menu.Item>
                    </>
                }
            </Menu.Dropdown>
        </Menu>
    );
};

export default ContextMenu;