import { useTranslation } from 'react-i18next';
import { IScheme } from '../../../../api/scheme';
import InfoItem from '../../../../components/CrudModal/InfoItem';
import { Button, Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const SchemeListModalInfo = ({ record, isFetching }: { record?: IScheme, isFetching: boolean }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const fullName = record?.author ? `${record.author.first_name} ${record.author.last_name}` : '';

    return (
        <div>
            <InfoItem label={t('audit.author')} value={fullName}></InfoItem>
            <InfoItem label={t('login.email')} value={record?.author?.email}></InfoItem>

            <Flex
                mt={20}
                justify={"flex-end"}
            >
                <Button
                    variant="filled"
                    onClick={() => navigate("/dictionary/schemes/" + record!.id)}
                >
                    {t('common.view')}
                </Button>
            </Flex>
        </div >
    );
};

export default SchemeListModalInfo;
