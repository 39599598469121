import {api} from "../app/api";
import {IUser} from "./user";

export interface IContent {
    id?: number,
    value: string,
    language: string
    category: string,
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IContentResponse {
    count: number;
    next: any;
    previous: any;
    results: IContent[];
}

export interface IContentRead {
    id: number,
    value: string,
    language: string
    category: string,

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export const webContentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getContent: build.query<IContentResponse, {
            ordering?: string,
            page?: number,
            page_size?: number,
            language?: string,
        }>({
            query: (params) => ({
                url: `/content/admin/content/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    language: params.language
                }
            }),
            providesTags: (result, error, tag) => [{type: "WebContent", tag}]
        }),
        createContent: build.mutation<any, IContent>({
            query: (payload) => ({
                url: "/content/admin/content/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: "WebContent"}]
        }),
        updateContent: build.mutation<any, IContentRead>({
            query: (payload) => ({
                url: `/content/admin/content/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: (result) => (result ? [{type: "WebContent"}] : ['ERROR'])
        }),
        readContent: build.query<IContentRead, { id?: number, language?: string }>({
            query: (params) => ({
                url: `/content/admin/content/${params.id}/`,
                method: "GET",
                // params: {
                //     language: params.language
                // }
            }),
            providesTags: (result, error, tag) => [{type: "ContentCategory", tag}],
        }),
        removeContent: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/content/admin/content/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{type: "WebContent"}]
        })
    })
});

export const {
    useCreateContentMutation,
    useGetContentQuery,
    useLazyReadContentQuery,
    useUpdateContentMutation,
    useRemoveContentMutation,
    useReadContentQuery,
} = webContentApi;
