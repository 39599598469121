import { Title, InputLabel, Divider, NumberInput, TextInput, Radio, Flex, Button, Select, Paper, ColorInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { TextToInsert } from '../interfaces/SchemeEdit.interface';

interface TextOptionsProps {
    px?: string;
    data: any;
    textInsertMode: boolean;
    selectedText?: string;
    textToInsert: TextToInsert;
    setTextToInsert: React.Dispatch<React.SetStateAction<TextToInsert>>;
    setData: React.Dispatch<React.SetStateAction<any>>;
    setSelectedText: React.Dispatch<React.SetStateAction<any>>;
    setText: React.Dispatch<React.SetStateAction<any>>;
    t: (key: string) => string;
}

const TextOptions: React.FC<TextOptionsProps> = ({
    px,
    data,
    textInsertMode,
    selectedText,
    textToInsert,
    setTextToInsert,
    setData,
    setSelectedText,
    setText,
    t
}) => {
    const { text } = textToInsert.properties;
    const textIsNumber = typeof text === 'object' ? false : true;
    const [isNumber, setIsNumber] = useState<boolean>(textIsNumber);
    const setTextToEdit = () => {
        if (selectedText) {
            const selectedTextData = data.properties.text[toNumber(selectedText)];

            if (!selectedTextData) {
                return;
            }

            let textRow: any;

            if (typeof selectedTextData.properties!.text! === 'string') {
                textRow = selectedTextData.properties.text;
                setIsNumber(true);
            } else {
                setIsNumber(false);

                textRow = {
                    en: selectedTextData.properties.text?.en,
                    ro: selectedTextData.properties.text?.ro,
                    ru: selectedTextData.properties.text?.ru,
                };
            }

            setTextToInsert(selectedTextData);
        }
    };

    const handleRadioChange = (value: string) => {
        setIsNumber(value === 'number')

        setTextToInsert(prev => ({
            ...prev,
            properties: {
                ...prev.properties,
                text: '',
                type: 'text',
            },
            rect: prev.rect,
            styles: prev.styles,
        }));
    };

    const handleUpdateDataText = () => {
        const index = toNumber(selectedText);

        if (isNaN(index)) {
            console.error('Invalid index');
            return;
        }

        setData((prevData: { properties: { text: any[] } }) => {
            const updatedText = [...prevData.properties.text];

            if (isNumber) {
                updatedText[index] = {
                    ...updatedText[index],
                    properties: {
                        ...updatedText[index].properties,
                        text: textToInsert.properties.text,
                    },
                    styles: {
                        ...updatedText[index].styles,
                        ...textToInsert.styles
                    }
                };
            } else {
                updatedText[index] = {
                    ...updatedText[index],
                    properties: {
                        ...updatedText[index].properties,
                        text: { ...textToInsert.properties.text },
                    },
                    styles: {
                        ...updatedText[index].styles,
                        ...textToInsert.styles
                    }
                };
            }

            return {
                ...prevData,
                properties: {
                    text: updatedText,
                },
            };
        });

        setText((prevText: any[]) => {
            const updatedText = [...prevText];
            updatedText[index] = textToInsert;
            return updatedText;
        });

        setSelectedText('');
    };

    useEffect(() => {
        if (selectedText && selectedText !== '') {
            if (textInsertMode) {
                console.error(textInsertMode)
                setSelectedText('')
                setTextToInsert({
                    rect: {
                        x: '',
                        y: ''
                    },
                    properties: {
                        text: '',
                        type: 'text'
                    },
                    styles: {
                        fontSize: undefined,
                        writingMode: undefined,
                        fill: undefined
                    }
                });
            } else {
                setTextToEdit()
            }
        }
    }, [isNumber, selectedText, textInsertMode])

    return (
        <>
            <Divider my={10} />

            <Paper px={px}>
                <Title mb={6} order={4}>{t('scheme.redactor.panelOptions.setText.title')}</Title>
                <InputLabel>{t('scheme.redactor.panelOptions.setText.selectMode')}</InputLabel>

                <Radio.Group
                    value={isNumber ? 'number' : 'word'}
                    onChange={(value) => { handleRadioChange(value) }}
                    py={10}
                >
                    <Flex gap={10}>
                        <Radio value="number" label={t('scheme.redactor.panelOptions.setText.numberMode')} />
                        <Radio value="word" label={t('scheme.redactor.panelOptions.setText.wordMode')} />
                    </Flex>
                </Radio.Group>
            </Paper>

            <Divider my={10} />

            <Paper px={px}>
                <Select
                    label={t('scheme.redactor.panelOptions.setText.writingMode.title')}
                    mb={5}
                    value={textToInsert.styles.writingMode || 'horizontal-tb'}
                    onChange={(value) => setTextToInsert(prev => ({
                        ...prev,
                        styles: {
                            ...prev.styles,
                            writingMode: value || 'horizontal-tb',
                        }
                    }))}
                    data={[
                        { value: 'horizontal-tb', label: t('scheme.redactor.panelOptions.setText.writingMode.horizontal-tb') },
                        { value: 'vertical-rl', label: t('scheme.redactor.panelOptions.setText.writingMode.vertical-rl') },
                        { value: 'vertical-lr', label: t('scheme.redactor.panelOptions.setText.writingMode.vertical-lr') },
                        { value: 'sideways-rl', label: t('scheme.redactor.panelOptions.setText.writingMode.sideways-rl') },
                        { value: 'sideways-lr', label: t('scheme.redactor.panelOptions.setText.writingMode.sideways-lr') },
                    ]}
                />

                <ColorInput
                    label={t('scheme.redactor.panelOptions.setFigure.polilyne.strokeColor')}
                    value={textToInsert.styles.fill}
                    onChange={(value) => setTextToInsert(prev => ({
                        ...prev,
                        styles: {
                            ...prev.styles,
                            fill: value,
                        },
                    }))}
                />

                <Flex gap={10} align={"center"}>
                    <NumberInput
                        mb={5}
                        label={t('scheme.redactor.panelOptions.setText.fontSize')}
                        placeholder={t('scheme.redactor.panelOptions.setText.fontSize')}
                        value={textToInsert.styles.fontSize || ''}
                        hideControls
                        onChange={(value) => setTextToInsert(prev => ({
                            ...prev,
                            styles: {
                                ...prev.styles,
                                fontSize: Number(value)
                            }
                        }))}
                    />

                    <NumberInput
                        mb={5}
                        label={t('scheme.redactor.panelOptions.setText.rotateText')}
                        placeholder={t('scheme.redactor.panelOptions.setText.rotateText')}
                        value={textToInsert.styles.transform ? Number(textToInsert.styles.transform.match(/-?\d+/)?.[0]) : ''}
                        hideControls
                        onChange={(value) => setTextToInsert(prev => ({
                            ...prev,
                            styles: {
                                ...prev.styles,
                                transform: `rotate(${Number(value)})`
                            }
                        }))}
                    />
                </Flex>

                {isNumber &&
                    <>
                        <InputLabel>{t('scheme.redactor.panelOptions.setText.writeNumber')}</InputLabel>

                        <TextInput
                            value={typeof text === 'string' ? text : ""}
                            onChange={(e) => {
                                setTextToInsert(prev => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        text: e.target.value,
                                    }
                                }));
                            }}
                            placeholder={t('scheme.redactor.panelOptions.setText.writeNumber')}
                        />
                    </>
                }

                {!isNumber && (
                    <>
                        <Title mt={10} order={4}>{t('scheme.redactor.panelOptions.setText.translations.title')}</Title>

                        <InputLabel mt={10}>{t('scheme.redactor.panelOptions.setText.translations.en.title')}</InputLabel>
                        <TextInput
                            value={text.en || ''}
                            onChange={(e) => {
                                setTextToInsert(prev => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        text: {
                                            ...((typeof prev.properties.text === 'object') ? prev.properties.text : { en: '', ro: '', ru: '' }),
                                            en: e.target.value
                                        }
                                    }
                                }));
                            }}
                            placeholder={t('scheme.redactor.panelOptions.setText.translations.en.placeholder')}
                        />

                        <InputLabel mt={10}>{t('scheme.redactor.panelOptions.setText.translations.ro.title')}</InputLabel>
                        <TextInput
                            value={text.ro || ''}
                            onChange={(e) => {
                                setTextToInsert(prev => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        text: {
                                            ...((typeof prev.properties.text === 'object') ? prev.properties.text : { en: '', ro: '', ru: '' }),
                                            ro: e.target.value
                                        }
                                    }
                                }));
                            }}
                            placeholder={t('scheme.redactor.panelOptions.setText.translations.ro.placeholder')}
                        />

                        <InputLabel mt={10}>{t('scheme.redactor.panelOptions.setText.translations.ru.title')}</InputLabel>
                        <TextInput
                            value={text.ru || ''}
                            onChange={(e) => {
                                setTextToInsert(prev => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        text: {
                                            ...((typeof prev.properties.text === 'object') ? prev.properties.text : { en: '', ro: '', ru: '' }),
                                            ru: e.target.value
                                        }
                                    }
                                }));
                            }}
                            placeholder={t('scheme.redactor.panelOptions.setText.translations.ru.placeholder')}
                        />
                    </>
                )}

                {
                    selectedText &&
                    <Button
                        my={15}
                        onClick={handleUpdateDataText}
                    >
                        {t('common.save')}
                    </Button>
                }
            </Paper>
        </>
    );
};

export default TextOptions;
